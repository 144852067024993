import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends GerinusService {

  constructor(public http: HttpClient) {
    super(http);
    this.entityName = 'tags';
    this.idField = 'TAG_CodigoTag';
  }
}
