import { Component, Input, OnInit } from '@angular/core';
import FormRequest from '../common/formRequest';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { getValuesActivity, getValuesGroupActivity } from './utilities/values-activity';


@Component({
  selector: 'app-form-request-semurb',
  templateUrl: './form-request-semurb.component.html',
  styleUrls: ['./form-request-semurb.component.scss'],
})
export class FormRequestSemurbComponent extends FormRequest implements OnInit {

  @Input() applicantDetails;

  tipoProcesso: string[] = [
    'Carta de Anuência (CA)',
    'Licença Mineral (LM)',
    'Licença Prévia (LP)',
    'Licença Prévia e de Instalação (LPI)',
    'Licença de Instalação (LI)',
    'Licença de Instalação e Ampliação (LIAM)',
    'Licença de Instalação e Operação (LIO)',
    'Licença de Operação (LO)',
    'Licença Ambiental Única (LAU)',
    'Licença Ambiental por Adesão e Compromisso (LAC)',
    'Autorização Ambiental (AA)',
    'Autorização para Uso Alternativo do Solo (UAS)',
    'Autorização de Supressão Vegetal (ASV)',
    'Autorização para Corte de Árvores Isoladas de Espécie Nativa (CAI)',
    'Declaração de Inexigibilidade Ambiental'
  ];

  modalidades: { value: string, label: string, subOptions?: string[] }[] = [
    { value: 'Renovação', label: 'Renovação' },
    {
      value: 'Regularização', label: 'Regularização', subOptions: [
        'Empreendimento ou Atividade sem qualquer licenciamento ambiental',
        'Empreendimento ou Atividade com licença vencida'
      ]
    },
    { value: 'Mudança de Titularidade', label: 'Mudança de Titularidade' },
    { value: 'Alteração/Retificação de Licença', label: 'Alteração/Retificação de Licença' }
  ];

  gruposAtividade = getValuesGroupActivity();

  atividades = getValuesActivity();

  selectedGroup: string = '';

  constructor(
    public fb: FormBuilder,
    public fiscoService: FiscoService
  ) {
    super(fb, fiscoService);
  }

  ngOnInit(): void {
    this.initForm();
    this.formChanges();
    this.verifySuppression();
  }

  updateEnderecoForm(endereco: any, prefix: string) {
    this.form.patchValue({
      [`${prefix}CEP`]: endereco.CEP,
      [`${prefix}UF`]: endereco.uf,
      [`${prefix}Municipio`]: endereco.localidade,
      [`${prefix}Logradouro`]: endereco.logradouro,
      [`${prefix}Numero`]: endereco.numero,
      [`${prefix}Complemento`]: endereco.complemento,
      [`${prefix}Bairro`]: endereco.bairro
    });
  }

  handleEnderecoChange(endereco: any) {
    this.updateEnderecoForm(endereco, 'SOLSM_Requerente');
  }

  updateActivityAddress(endereco: any) {
    this.updateEnderecoForm(endereco, 'SOLSM_Atividade');
  }

  updateResponsibleAddress(endereco: any) {
    this.updateEnderecoForm(endereco, 'SOLSM_Responsavel');
  }

  private initForm() {
    this.form = this.fb.group({
      formVariants: this.fb.group({
        SOL_Descricao: ["", Validators.required],
        suppressionYes: [true],
        suppressionNo: [false],
      }),
      SOLSM_TipoProcesso: ['', Validators.required],
      SOLSM_Modalidade: ['', Validators.required],
      SOLSM_SubtipoProcesso: ['', Validators.required],
      SOLSM_GrupoAtividade: ['', Validators.required],
      SOLSM_Atividade: ['', Validators.required],

      // Requerente
      SOLSM_RequerenteNomeRazao: [this.applicantDetails.SOLR_NomeRazaoSocial, Validators.required],
      SOLSM_RequerenteCpfCnpj: [this.applicantDetails.SOLR_CpfCnpj, Validators.required],
      SOLSM_RequerenteEmail: ['', Validators.required],
      SOLSM_RequerenteTelefone: ['', Validators.required],
      SOLSM_RequerenteCEP: ['', Validators.required],
      SOLSM_RequerenteUF: ['', Validators.required],
      SOLSM_RequerenteMunicipio: ['', Validators.required],
      SOLSM_RequerenteLogradouro: ['', Validators.required],
      SOLSM_RequerenteNumero: ['', Validators.required],
      SOLSM_RequerenteComplemento: [''],
      SOLSM_RequerenteBairro: ['', Validators.required],

      // Atividade/Empreeendimento
      SOLSM_AtividadeNomeEmpreendimento: ['', Validators.required],
      SOLSM_AtividadeCEP: ['', Validators.required],
      SOLSM_AtividadeUF: ['', Validators.required],
      SOLSM_AtividadeMunicipio: ['', Validators.required],
      SOLSM_AtividadeLogradouro: ['', Validators.required],
      SOLSM_AtividadeNumero: ['', Validators.required],
      SOLSM_AtividadeComplemento: [''],
      SOLSM_AtividadeBairro: ['', Validators.required],
      SOLSM_AtividadeAreaConstruida: [null, Validators.required],
      SOLSM_AtividadeNumeroFuncionarios: [null, Validators.required],
      SOLSM_AtividadeLicencaAnterior: ['', Validators.required],
      SOLSM_AtividadeSupressaoVegetal: [null, Validators.required],

      // Responsavel
      SOLSM_ResponsavelNome: ['', Validators.required],
      SOLSM_ResponsavelCPF: ['', Validators.required],
      SOLSM_ResponsavelEmail: ['', Validators.required,],
      SOLSM_ResponsavelTelefone: ['', Validators.required],
      SOLSM_ResponsavelCEP: ['', Validators.required],
      SOLSM_ResponsavelUF: ['', Validators.required],
      SOLSM_ResponsavelMunicipio: ['', Validators.required],
      SOLSM_ResponsavelLogradouro: ['', Validators.required],
      SOLSM_ResponsavelNumero: ['', Validators.required],
      SOLSM_ResponsavelComplemento: [''],
      SOLSM_ResponsavelBairro: ['', Validators.required],

      // Consultoria
      SOLSM_ConsultoriaNome: [''],
      SOLSM_ConsultoriaCpfCnpj: [''],
      SOLSM_ConsultoriaEmail: [''],
      SOLSM_ConsultoriaTelefone: ['']
    })
  }

  verifySuppression() {
    this.form.get('formVariants').get('suppressionYes').valueChanges.subscribe((value) => {
      this.form.get('formVariants').get('suppressionNo').setValue(!value, { emitEvent: false })
      this.form.updateValueAndValidity();
      this.enableAnddisableSupression();
    })
    this.form.get('formVariants').get('suppressionNo').valueChanges.subscribe((value) => {
      this.form.get('formVariants').get('suppressionYes').setValue(!value, { emitEvent: false })
      this.form.updateValueAndValidity();
      this.enableAnddisableSupression();
    })
  }

  private suppresionValidator(control: AbstractControl): ValidationErrors | null {
    const suppressionYes = control.get('formVariants').get('suppressionYes')?.value;

    if (suppressionYes) {
      return { required: true };
    }
    return null;
  }

  enableAnddisableSupression() {
    if (this.form.get('formVariants').get('suppressionYes').value) {
      this.form.get('SOLSM_AtividadeSupressaoVegetal').enable();
    } else {
      this.form.get('SOLSM_AtividadeSupressaoVegetal').setValue(null);
      this.form.get('SOLSM_AtividadeSupressaoVegetal').disable();
    }
    this.form.updateValueAndValidity();
  }

  onGroupChange(selectedValue: string) {
    this.selectedGroup = selectedValue;
    this.atividades = this.getFilteredActivities(selectedValue);
  }

  private getFilteredActivities(groupValue: string) {
    return getValuesActivity().filter(activity => activity.grupo === groupValue);
  }

}