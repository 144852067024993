import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[CPF]'
})
export class CpfMaskDirective implements AfterViewInit {

  constructor(public el: ElementRef) { }

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute('placeholder', '000.000.000-00');
    this.el.nativeElement.setAttribute('mask', '000.000.000-00');
    this.el.nativeElement.setAttribute('type', 'text');
  }

}