import { Component, Input } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sign-gov-br',
    templateUrl: './sign-gov-br.component.html',
})
export class SignGovBrComponent {

    public signUrl: string;

    constructor(
        public global: GlobalService,
        public router: Router,
    ) { }

    public get returnUrl(): any {
        return encodeURIComponent(`${environment.govBR.returnSignUrl}`)
    }

    public get returnEnvelopedUrl(): any {
        return encodeURIComponent(`${environment.govBR.returnSignUrl}?enveloped=true`)
    }

    public signGovBr() {
        this.signUrl = `${environment.govBR.signUrl}/authorize?response_type=code&redirect_uri=${this.returnUrl}&scope=sign&client_id=${environment.govBR.signClientID}`;
        window.location.href = this.signUrl;
    }

    public signEnvelopedGovBr() {
        this.signUrl = `${environment.govBR.signUrl}/authorize?response_type=code&redirect_uri=${this.returnEnvelopedUrl}&scope=sign&client_id=${environment.govBR.signClientID}`;
        window.location.href = this.signUrl;
    }
}
