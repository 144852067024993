import { Component, OnInit } from '@angular/core';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { AtendimentoService } from 'src/app/app-core/services/atendimento.service';
import { PriorityServiceEnum } from '../../Enums/priorityServiceEnum';
import { StatusServiceEnum } from 'src/app/Enums/statusServiceEnum';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  providers: [AtendimentoService]
})
export class ServiceComponent extends GerinusBD {

  constructor(public service: AtendimentoService) {
    super(null, service);
    this.afterCreate(() => { this.mapFieldsList() });

    this.filters = [
      {
        name: 'Nome',
        columName: 'PE_Nome',
      },
      {
        name: 'Grupo',
        columName: 'GRP_Descricao',
      },
      {
        name: 'Departamento',
        columName: 'DEP_Descricao',
      },
      {
        name: 'Órgão',
        columName: 'OR_Descricao',
      },
    ];
  }

  // public getStyleFromAtendimento(atendimento) {
  //   switch (prioridade) {
  //     case PriorityServiceEnum.ALTA:
  //       return "chip chip-warning";

  //     case PriorityServiceEnum.URGENTE:
  //       return "chip chip-danger";

  //     case PriorityServiceEnum.MEDIA:
  //       return "chip chip-medium";

  //     case PriorityServiceEnum.BAIXA:
  //       return "chip chip-success";

  //     default:
  //       break;
  //   }
  // }

  /** esta função, mapeia os campos do array e adiciona novas chaves como a Label e cores
   * isso foi feito para evitar funções chamadas no HTML e fazendo com que a verificação  dos campos 
   * seja feita somente uma vez.
   */
  private mapFieldsList(): void {
    this.list = this.list.map((item) => {
      console.log("[mapFieldsList] item: ", item);

      return {
        ...item,
        label: this.getLabel(item.ATD_Status),
        chip: this.getChipFromPrioridade(item.ATD_Prioridade).class,
        chipLabel: this.getChipFromPrioridade(item.ATD_Prioridade).text
      };
    });
  }

  private getLabel(status: string) {
    switch (status) {
      case StatusServiceEnum.ABERTO:
        return "Aberto"
      case StatusServiceEnum.PENDENTE:
        return "Pendente"
      case StatusServiceEnum.RESOLVIDO:
        return "Resolvido"
    }
  }

  private getChipFromPrioridade(prioridade: string) {
    switch (prioridade) {
      case PriorityServiceEnum.ALTA:
        return {
          class: "chip chip-warning",
          text: "Alta"
        };

      case PriorityServiceEnum.URGENTE:
        return {
          class: "chip chip-danger",
          text: "Urgente"
        };

      case PriorityServiceEnum.MEDIA:
        return {
          class: "chip chip-medium",
          text: "Média"
        };

      case PriorityServiceEnum.BAIXA:
        return {
          class: "chip chip-success",
          text: "Baixa"
        }

      default:
        break;
    }
  }
}

