import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class CompanyService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'empresa';
        this.idField = 'EMP_CodigoEmpresa';
        this.model = 'EmpresaModel';
    }

    public getThemeByURL(url) {
        if (url == '') url = '-1';
        return this.http.post(this.apiURL() + '/api/theme', { url: url });
    }
}
