import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DocumentService } from 'src/app/app-core/services/document.service';
import { LoginService } from 'src/app/app-core/services/login.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { RequestService } from 'src/app/app-core/services/request.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [LoginService, DocumentService, ProcessService, RequestService]
})
export class HomePageComponent implements OnInit {

  public searchValue: string;
  public navbarOpen: boolean = false;
  public loadScreen = true;
  public messages;
  public isFaqCardVisible: boolean = false;
  public authKeyDocument: string;
  public accordionItems;
  public currentDate: Date = new Date();


  constructor(
    public service: LoginService,
    public route: ActivatedRoute,
    public global: GlobalService,
    public documentService: DocumentService,
    public processService: ProcessService,
    public requestService: RequestService,
    public activatedRoute: ActivatedRoute,
  ) {
    const decodedPath = decodeURIComponent(window.location.href.split('redirect=')[1]);
    const queryString = decodedPath.split('?')[1];
    const queryParams = this.global.decodeQueryString(queryString);

    if (queryParams.code) {
      this.loadScreen = false;
      this.global.startLoading();
      const code = queryParams.code;
      this.service.authentication(true, code);
      this.global.stopLoading();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.logout == 'true') {
        this.service.logout();
      }
    });
  }

  ngOnInit(): void {
    const title = document.querySelector('title');
    title.text = 'Bem-vindo ao Protocolize SGA';
    this.route.params.subscribe((params) => {
      if (params.type && params.token) {
        const payload = {
          type: params.type,
          accessToken: params.token,
        };

        this.service.authentication(payload, 'isGovBr');
      }
    });

    this.accordionItems = [
      {
        id: 1,
        title: 'Como entrar no sistema?',
        content: 'Para acessar o ProtocolizeSGA, é necessário possuir uma conta <b>gov.br</b>. Se você ainda não possui uma, pode criar facilmente seguindo <a href="https://www.gov.br/pt-br/servicos/criar-sua-conta-gov.br">este link.</a>A conta gov.br concede acesso a uma variedade de serviços digitais oferecidos pelo governo, incluindo o ProtocolizeSGA. Utilizaremos essa conta para identificá-lo no sistema.',
        expanded: false
      },
      {
        id: 2,
        title: 'Quem pode utilizar o ProtocolizeSGA?',
        content: 'Qualquer cidadão que necessite de um dos serviços oferecidos pelos órgãos do município de São Gonçalo do Amarante pode enviar uma solicitação, a qual será avaliada pelo órgão responsável pelo serviço.',
        expanded: false
      },
      {
        id: 3,
        title: "Quais assuntos consigo tratar no protocolizeSGA?",
        content: `Cada secretaria possui suas respectivas responsabilidades e podem tratar dos mais diversos assuntos com o cidadão. Para conhecer melhor, faça o download da lista de asssuntos <a href="https://sefin.pmsga.ce.gov.br/wp-content/uploads/2024/09/Protocolize-SGA-Lista-de-Assuntos.pdf" target="_blank">neste link</a>.`,
        expanded: false
      },
      {
        id: 4,
        title: 'O que é um processo?',
        content: 'Um processo geralmente consiste em uma solicitação formalizada por um cidadão ou entidade para obter um determinado serviço, licença, autorização ou resolver uma questão administrativa relacionada à jurisdição municipal.',
        expanded: false
      },
      {
        id: 5,
        title: 'Como validar meu processo ou solicitação?',
        content: `No PDF que contém o resumo da solicitação/processo, ao final terá um código de verificação. Esse código é único e irá servir para fins de validação. Para verificar se o documento é válido, você deve acessar e digitar o código de verificação. Também é possível validar através do QR code que também está contido nos documentos.`,
        expanded: false
      },
      {
        id: 6,
        title: "Minha solicitação precisa de correção, e agora?",
        content: `Se sua solicitação foi avaliada pela equipe de triagem e a mesma mudou o status para "Correção de Pendências", você deve avaliar as informações da avaliação e realizar as devidas correções. Na maioria dos casos, você deve enviar um novo documento, para isso, anule o documento anterior clicando no ícone "x" e selecione um novo arquivo corrigido.`,
        expanded: false
      },
    ]
  }

  public search() {
    this.global.router.navigate(['/search-process'], { queryParams: { searchValue: this.searchValue } });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleAccordion(item: any): void {
    this.accordionItems.forEach(i => {
      if (i !== item) {
        i.expanded = false;
      }
    });

    item.expanded = !item.expanded;
  }

  public navClick(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }

  public getDocumentbyAuthKey() {
    if (this.authKeyDocument.length === 12) {
        this.global.startLoading();
        this.documentService.getVericityOfDocument(this.authKeyDocument).subscribe({
            next: (response: any) => {
                this.global.stopLoading();
                this.global.swal.fire(response.swal).then((result) => {
                    if (result.isConfirmed && response.ok) {
                        if (response.type === 'documento') {
                            this.global.swal.fire({
                                title: response.entity.DOC_Assunto,
                                html: response.entity.DOC_Conteudo,
                            });
                        } else if (response.type === 'solicitacao') {
                            this.requestService.generateRequestPDF(response.entity);
                        } else if (response.type === 'processo') {
                            this.processService.generateProcessPDF(response.entity);
                        }
                    }
                });
            },
        });
    } else {
        this.global.swal.fire({
            icon: 'error',
            title: 'chave inválida!',
            text: 'As chaves de autenticidade possuem somente números e contem apenas 12 caracteres.',
        });
    }
}
}
