export function getValuesGroupActivity() {
    return [
        { value: '01.00', label: 'Agropecuária' },
        { value: '02.00', label: 'Aquicultura' },
        { value: '03.00', label: 'Coleta, Transporte, Armazenamento e Tratamento de Resíduos Sólidos e Produtos' },
        { value: '04.00', label: 'Atividades Florestais' },
        { value: '05.00', label: 'Indústria de Beneficiamento de Minerais' },
        { value: '06.00', label: 'Comércio e Serviços' },
        { value: '07.00', label: 'Construção Civil' },
        { value: '08.00', label: 'Extração de Minerais' },
        { value: '09.00', label: 'Geração, Transmissão e Distribuição de Energia Elétrica' },
        { value: '10.00', label: 'Indústria de Beneficiamento de Borracha' },
        { value: '11.00', label: 'Indústria de Beneficiamento de Couros e Peles' },
        { value: '12.00', label: 'Indústria de Beneficiamento de Fumo' },
        { value: '13.00', label: 'Indústria de Beneficiamento de Madeira' },
        { value: '14.00', label: 'Indústria de Material de Transporte' },
        { value: '15.00', label: 'Indústria de Material Elétrico, Eletrônico e de Comunicação' },
        { value: '16.00', label: 'Indústria de Beneficiamento de Produtos Agrícolas' },
        { value: '17.00', label: 'Indústria de Beneficiamento de Papel e Celulose' },
        { value: '18.00', label: 'Indústria de Produtos Alimentares e Bebidas' },
        { value: '19.00', label: 'Indústria de Produtos de Matéria Plástica' },
        { value: '20.00', label: 'Indústria Mecânica' },
        { value: '21.00', label: 'Indústria Metalúrgica' },
        { value: '22.00', label: 'Indústria Química' },
        { value: '23.00', label: 'Indústria Têxtil, de Vestuário, Calçados e Artefatos de Tecidos, Couro e Peles' },
        { value: '24.00', label: 'Indústrias Diversas' },
        { value: '25.00', label: 'Infraestrutura Urbanística/Paisagística' },
        { value: '26.00', label: 'Infraestrutura Viária e de Obras de Arte' },
        { value: '27.00', label: 'Saneamento Ambiental' },
        { value: '29.00', label: 'Obras Hídricas' },
        { value: '30.00', label: 'Empreendimentos Turísticos' }
    ];
}

export function getValuesActivity() {
    return [
        { value: '01.01', label: 'Criação de Animais - sem abate (avicultura)', grupo: '01.00' },
        { value: '01.01', label: 'Criação de animais - sem abate ovinocaprinocultura', grupo: '01.00' },
        { value: '01.01', label: 'Criação de animais - sem abate (suinocultura)', grupo: '01.00' },
        { value: '01.01', label: 'Criação de animais - sem abate (bovinocultura/bubalinocultura)', grupo: '01.00' },
        { value: '01.02', label: 'Cultivo de Plantas Medicinais, Aromáticas e Condimentares', grupo: '01.00' },
        { value: '01.04', label: 'Cultivo de flores e plantas ornamentais (sem uso de agrotóxico)', grupo: '01.00' },
        { value: '01.06', label: 'Projetos Agrícolas de sequeiro (sem uso de agrotóxico)', grupo: '01.00' },
        { value: '01.08', label: 'Projetos de Irrigação (sem uso de agrotóxico)', grupo: '01.00' },
        { value: '01.12', label: 'Outras atividades não especificadas anteriormente', grupo: '01.00' },
        { value: '02.04', label: 'Piscicultura - Produção em Tanque-rede', grupo: '02.00' },
        { value: '02.04', label: 'Piscicultura Ornamental', grupo: '02.00' },
        { value: '02.04', label: 'Piscicultura Pesque e Pague', grupo: '02.00' },
        { value: '02.04', label: 'Algicultura e Malacocultura', grupo: '02.00' },
        { value: '02.04', label: 'Outras atividades não especificadas anteriormente', grupo: '02.00' },
        { value: '03.01', label: 'Coleta e Transporte de Resíduos Classe I - Perigosos', grupo: '03.00' },
        { value: '03.02', label: 'Coleta e Transporte de Resíduos de Classe II - Não Perigosos', grupo: '03.00' },
        { value: '03.03', label: 'Coleta e Transporte de Resíduos de Serviços de Saúde', grupo: '03.00' },
        { value: '03.04', label: 'Coleta e Transporte de Resíduos da Construção Civil', grupo: '03.00' },
        { value: '03.05', label: 'Coleta e Transporte de Efluentes Líquidos', grupo: '03.00' },
        { value: '03.06', label: 'Coleta e transporte de Cargas Perigosas, Produtos Perigosos ou Inflamáveis', grupo: '03.00' },
        { value: '03.07', label: 'Armazenamento de Resíduos da Construção Civil', grupo: '03.00' },
        { value: '03.08', label: 'Armazenamento de Produtos Perigosos ou Inflamáveis', grupo: '03.00' },
        { value: '03.09', label: 'Armazenamento de Resíduos Classe I - Perigosos', grupo: '03.00' },
        { value: '03.10', label: 'Armazenamento de Resíduos de Classe II - Não Perigosos', grupo: '03.00' },
        { value: '03.11', label: 'Armazenamento de Resíduos de Serviços de Saúde', grupo: '03.00' },
        { value: '03.12', label: 'Armazenamento e Distribuição de Produtos Não Perigosos', grupo: '03.00' },
        { value: '03.13', label: 'Tratamento de Resíduos da Construção Civil', grupo: '03.00' },
        { value: '03.14', label: 'Tratamento de Resíduos Sólidos - Classe II - Não Perigosos', grupo: '03.00' },
        { value: '03.15', label: 'Tratamento de Resíduos Sólidos - Classe I - Perigosos A(AA)', grupo: '03.00' },
        { value: '03.16', label: 'Tratamento de Resíduos Sólidos por Compostagem', grupo: '03.00' },
        { value: '03.17', label: 'Tratamento de Resíduos Sólidos para Fins de Pesquisa Científica', grupo: '03.00' },
        { value: '03.18', label: 'Usina de Reciclagem/Triagem de Resíduos', grupo: '03.00' },
        { value: '03.22', label: 'Aterro Sanitário', grupo: '03.00' },
        { value: '03.23', label: 'Aterro de Resíduos da Construção Civil', grupo: '03.00' },
        { value: '03.25', label: 'Disposição de resíduos especiais de serviços de saúde e similares', grupo: '03.00' },
        { value: '03.27', label: 'Coleta, Transporte e Armazenamento de Resíduos Sólidos e Produtos', grupo: '03.00' },
        { value: '03.28', label: 'Outras atividades não especificadas anteriormente', grupo: '03.00' },
        { value: '04.01', label: 'Autorização Para Uso Alternativo do Solo (AUS)', grupo: '04.00' },
        { value: '04.02', label: 'Autorização de Supressão de Vegetação (ASV)', grupo: '04.00' },
        { value: '04.03', label: 'Autorização de Uso do Fogo Controlado', grupo: '04.00' },
        { value: '04.06', label: 'Autorização de Corte de Árvores Isoladas (CAI)', grupo: '04.00' },
        { value: '05.01', label: 'Beneficiamento de gemas', grupo: '05.00' },
        { value: '05.02', label: 'Beneficiamento de Calcário', grupo: '05.00' },
        { value: '05.03', label: 'Britagem e/ou Moagem de Rochas, exceto Calcário', grupo: '05.00' },
        { value: '05.04', label: 'Fabricação de Produtos e Artefatos Cerâmicos', grupo: '05.00' },
        { value: '05.05', label: 'Produção de Gesso', grupo: '05.00' },
        { value: '05.06', label: 'Produção de Cimento', grupo: '05.00' },
        { value: '05.07', label: 'Beneficiamento de Minerais Metalíferos', grupo: '05.00' },
        { value: '05.08', label: 'Fabração de Artefatos de Rochas Ornamentais', grupo: '05.00' },
        { value: '05.09', label: 'Ouictras atividades não especificadas anteriormente', grupo: '05.00' },
        { value: '06.01', label: 'Armazenamento, Fracionamento e Distribuição de Óleos Vegetais, Essências para Desinfectantes e Álcool', grupo: '06.00' },
        { value: '06.03', label: 'Base de Revenda de Gás Liquefeito de Petróleo - GLP', grupo: '06.00' },
        { value: '06.04', label: 'Lavagem de veículos', grupo: '06.00' },
        { value: '06.07', label: 'Transporte Revendedor Retalhista (TRR) - Quando estiver circunscrito aos limites do município', grupo: '06.00' },
        { value: '06.09', label: 'Supermercados e Hipermercados', grupo: '06.00' },
        { value: '06.10', label: 'Oficina Mecânica com troca de óleo e/ou pintura automotiva', grupo: '06.00' },
        { value: '06.11', label: 'Shopping Center', grupo: '06.00' },
        { value: '06.12', label: 'Panificadoras, restaurantes e pizzarias - consumidores de Matéria-prima de Origem Florestal', grupo: '06.00' },
        { value: '06.13', label: 'Lavanderia Convencional sem esgotamento sanitário', grupo: '06.00' },
        { value: '06.14', label: 'Lavanderia Industrial/Hospitalar', grupo: '06.00' },
        { value: '06.15', label: 'Outras atividades não especificadas anteriormente', grupo: '06.00' },
        { value: '07.01', label: 'Condomínios e Conjuntos Habitacionais - Sem Infra- Estrutura', grupo: '07.00' },
        { value: '07.02', label: 'Condomínios e Conjuntos Habitacionais - Com Infra- Estrutura', grupo: '07.00' },
        { value: '07.03', label: 'Autódromos', grupo: '07.00' },
        { value: '07.04', label: 'Cemitérios', grupo: '07.00' },
        { value: '07.05', label: 'Construção de Muro de Contenção Micro, pequeno, médio, grande e excepcional impacto local', grupo: '07.00' },
        { value: '07.06', label: 'Distrito e Polo Industrial', grupo: '07.00' },
        { value: '07.07', label: 'Hipódromos', grupo: '07.00' },
        { value: '07.08', label: 'Hospitais', grupo: '07.00' },
        { value: '07.09', label: 'Clínicas e congêneres', grupo: '07.00' },
        { value: '07.10', label: 'Kartódromos', grupo: '07.00' },
        { value: '07.11', label: 'Laboratórios de Análises Clínicas, Biológicas, Radiológicas e Físico-Químicas', grupo: '07.00' },
        { value: '07.12', label: 'Penitenciárias', grupo: '07.00' },
        { value: '07.19', label: 'Terraplanagem M(AA)', grupo: '07.00' },
        { value: '07.20', label: 'Desmembramento do solo', grupo: '07.00' },
        { value: '07.21', label: 'Loteamento', grupo: '07.00' },
        { value: '07.22', label: 'Parques de Vaquejada', grupo: '07.00' },
        { value: '07.23', label: 'Outras atividades não especificadas anteriormente', grupo: '07.00' },
        { value: '08.01', label: 'Jazidas de empréstimo para obras civis', grupo: '08.00' },
        { value: '08.02', label: 'Extração, Envasamento e Gaseificação de água mineral (Campo) / (Poço)', grupo: '08.00' },
        { value: '08.03', label: 'Extração de Areia, Argila e Saibro (Desde que a extração não seja realizada em recursos hídricos)', grupo: '08.00' },
        { value: '08.04', label: 'Extração de Diatomito (Desde que a extração não seja realizada em recursos hídricos)', grupo: '08.00' },
        { value: '08.05', label: 'Extração de Rochas de Uso Imediato na Construção Civil', grupo: '08.00' },
        { value: '08.14', label: 'Extração de Sal', grupo: '08.00' },
        { value: '08.15', label: 'Outras atividades não especificadas anteriormente', grupo: '08.00' },
        { value: '09.01', label: 'Linhas de Distribuição até 15 kV', grupo: '09.00' },
        { value: '09.02', label: 'Linhas de Distribuição maior do que 15 kV e menor ou igual a 138 kV', grupo: '09.00' },
        { value: '09.03', label: 'Linhas de Transmissão até 138 kV', grupo: '09.00' },
        { value: '09.04', label: 'Linhas de Transmissão acima de 138 kV', grupo: '09.00' },
        { value: '09.05', label: 'Parque eólico/ usina eólica/ central eólica (Porte Micro)', grupo: '09.00' },
        { value: '09.06', label: 'Pequena Central Hidrelétrica - PCH (Porte Pequeno)', grupo: '09.00' },
        { value: '09.07', label: 'Subestação Abaixadora/Elevadora de Tensão/Seccionadora', grupo: '09.00' },
        { value: '09.08', label: 'Unidade de cogeração de energia elétrica', grupo: '09.00' },
        { value: '09.11', label: 'Energia Solar/ Fotovoltaica', grupo: '09.00' },
        { value: '09.12', label: 'Energia a partir de Biomassas/Biogás', grupo: '09.00' },
        { value: '09.13', label: 'Minigeração distribuída de energia elétrica a partir de fontes renováveis (Fotovoltaica)', grupo: '09.00' },
        { value: '09.14', label: 'Outras atividades não especificadas anteriormente', grupo: '09.00' },
        { value: '10.01', label: 'Beneficiamento de Borracha Natural', grupo: '10.00' },
        { value: '10.02', label: 'Fabricação de Espuma de Borracha e de Artefatos de Borracha, inclusive látex', grupo: '10.00' },
        { value: '10.03', label: 'Fabricação e Recondicionamento de pneumáticos', grupo: '10.00' },
        { value: '10.04', label: 'Recuperação de Pneumáticos', grupo: '10.00' },
        { value: '10.05', label: 'Outras atividades não especificadas anteriormente', grupo: '10.00' },
        { value: '11.01', label: 'Acabamento de Couros e Peles', grupo: '11.00' },
        { value: '11.02', label: 'Curtume e outras Preparações de Couros e Peles', grupo: '11.00' },
        { value: '11.03', label: 'Fabricação de Artefatos diversos de Couros e Peles', grupo: '11.00' },
        { value: '11.04', label: 'Fabricação de Cola Animal', grupo: '11.00' },
        { value: '11.05', label: 'Secagem e salga de couros e peles', grupo: '11.00' },
        { value: '11.06', label: 'Outros', grupo: '11.00' },
        { value: '12.01', label: 'Atividades de beneficiamento de fumo', grupo: '12.00' },
        { value: '12.02', label: 'Fabricação de cigarros, charutos, cigarrilhas e similares', grupo: '12.00' },
        { value: '12.03', label: 'Outros', grupo: '12.00' },
        { value: '13.01', label: 'Fabricação de Artefatos e Estrutura de Madeira e de Móveis, além de lápis, palitos e outros', grupo: '13.00' },
        { value: '13.02', label: 'Fabricação de Chapas, Placas de Madeira Aglomerada, Prensada e Compensada', grupo: '13.00' },
        { value: '13.03', label: 'Preservação e Tratamento de Madeira', grupo: '13.00' },
        { value: '13.04', label: 'Serraria e Desdobramento de Madeira', grupo: '13.00' },
        { value: '13.05', label: 'Produção de Carvão Vegetal', grupo: '13.00' },
        { value: '13.06', label: 'Outras atividades não especificadas anteriormente', grupo: '13.00' },
        { value: '14.01', label: 'Fabricação e montagem de carrocerias, tanques e caçambas para caminhões - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.02', label: 'Fabricação de Peças e Acessórios - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.03', label: 'Fabricação e Montagem de Aeronaves - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.04', label: 'Fabricação e Montagem de Veículos Ferroviários - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.05', label: 'Fabricação e Montagem de Veículos Rodoviários - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.06', label: 'Fabricação e Reparo de Embarcações e Estruturas Flutuantes - Desde que não haja tratamento de superfície', grupo: '14.00' },
        { value: '14.07', label: 'Outras atividades não especificadas anteriormente', grupo: '14.00' },
        { value: '15.01', label: 'Fabricação de Materiais e Componentes Elétricos e Eletrônicos - Desde que não haja tratamento de superfície', grupo: '15.00' },
        { value: '15.02', label: 'Fabricação de Aparelhos e Equipamentos Elétricos, Eletrônicos, Eletrodomésticos, Informática e Telecomunicações - Desde que não haja tratamento de superfície', grupo: '15.00' },
        { value: '15.03', label: 'Fabricação de Componentes Eletromecânicos - Desde que não haja tratamento de superfície', grupo: '15.00' },
        { value: '15.06', label: 'Outras atividades não especificadas anteriormente', grupo: '15.00' },
        { value: '16.01', label: 'Beneficiamento de Algodão', grupo: '16.00' },
        { value: '16.02', label: 'Beneficiamento de Cera de Carnaúba', grupo: '16.00' },
        { value: '16.03', label: 'Beneficiamento de Fibras Vegetais', grupo: '16.00' },
        { value: '16.04', label: 'Processamento de Sementes de Algodão', grupo: '16.00' },
        { value: '16.05', label: 'Outras atividades não especificadas anteriormente', grupo: '16.00' },
        { value: '17.01', label: 'Fabricação de Artefatos de Papel, Papelão, Cartolina, Cartão e Fibra Prensada', grupo: '17.00' },
        { value: '17.04', label: 'Transformação de Papel, inclusive Reciclados', grupo: '17.00' },
        { value: '17.05', label: 'Outras atividades não especificadas anteriormente', grupo: '17.00' },
        { value: '18.01', label: 'Agroindústria', grupo: '18.00' },
        { value: '18.02', label: 'Beneficiamento de sal', grupo: '18.00' },
        { value: '18.03', label: 'Envasamento e Gaseificação de Água Adicionada de Sais', grupo: '18.00' },
        { value: '18.04', label: 'Fabricação de Bebidas Alcoólicas', grupo: '18.00' },
        { value: '18.05', label: 'Fabricação de Bebidas Não-Alcoólicas', grupo: '18.00' },
        { value: '18.06', label: 'Fabricação de Doces e Conservas', grupo: '18.00' },
        { value: '18.07', label: 'Fabricação de Fermentos e Leveduras', grupo: '18.00' },
        { value: '18.08', label: 'Fabricação de Frios e Derivados de Carne', grupo: '18.00' },
        { value: '18.09', label: 'Fabricação de Massas Alimentícias', grupo: '18.00' },
        { value: '18.10', label: 'Fabricação de Rações Balanceadas e de Alimentos Preparados para Animais', grupo: '18.00' },
        { value: '18.11', label: 'Fabricação de Rapadura e Açúcar Mascavo', grupo: '18.00' },
        { value: '18.12', label: 'Fabricação de Vinagre', grupo: '18.00' },
        { value: '18.13', label: 'Matadouros, Abatedouros, Frigoríficos com abate, Charqueadas e derivados de origem animal', grupo: '18.00' },
        { value: '18.14', label: 'Preparação de Pescados e Fabricação de Conservas de Pescado', grupo: '18.00' },
        { value: '18.15', label: 'Preparação, Beneficiamento e Industrialização de Leite e Derivados - Laticínios', grupo: '18.00' },
        { value: '18.16', label: 'Refino/Preparação de Óleo e Gordura Vegetal', grupo: '18.00' },
        { value: '18.18', label: 'Fabricação de Gelo', grupo: '18.00' },
        { value: '18.19', label: 'Beneficiamento de Produtos Agrícolas (grãos, cereais, sementes, coco e polpa de fruta)', grupo: '18.00' },
        { value: '18.20', label: 'Beneficiamento de Produtos Agrícolas (mel de abelha, milho e trigo)', grupo: '18.00' },
        { value: '18.21', label: 'Outras atividades não especificadas anteriormente', grupo: '18.00' },
        { value: '19.01', label: 'Fabricação de Plástico/Artefatos de Material Plástico/Termoplástico/Sacos de Ráfia/Tecidos Plásticos/Produtos de Plástico tipo PVC e derivados', grupo: '19.00' },
        { value: '19.02', label: 'Fabricação de Laminados Plásticos', grupo: '19.00' },
        { value: '19.03', label: 'Fabricação de Móveis Plásticos', grupo: '19.00' },
        { value: '19.04', label: 'Produção de Espuma Plástica', grupo: '19.00' },
        { value: '19.05', label: 'Reciclagem de Plásticos', grupo: '19.00' },
        { value: '19.06', label: 'Outras atividades não especificadas anteriormente', grupo: '19.00' },
        { value: '20.01', label: 'Fabricação de Máquinas, Peças, Utensílios e Acessórios com Tratamento Térmico e sem Tratamento de Superfície', grupo: '20.00' },
        { value: '20.04', label: 'Fabricação de Máquinas, Peças, Utensílios e Acessórios sem Tratamento Térmico e sem Tratamento de Superfície', grupo: '20.00' },
        { value: '20.05', label: 'Fabricação de Instalações Frigoríficas', grupo: '20.00' },
        { value: '20.06', label: 'Fabricação de Máquinas de Costura - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município.', grupo: '20.00' },
        { value: '20.07', label: 'Fabricação de Refrigeradores - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '20.00' },
        { value: '20.08', label: 'Fabricação de Ventiladores - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '20.00' },
        { value: '20.09', label: 'Indústria de Geradores Eólicos e Elétricos - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '20.00' },
        { value: '20.10', label: 'Indústria Metalmecânica - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '20.00' },
        { value: '20.11', label: 'Industrialização de Sistemas Energéticos - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município. independentemente de haver tratamento de superfície, a natureza da atividade, associada ao elevado porte, produz impactos capazes de ultrapassar os limites do município', grupo: '20.00' },
        { value: '20.12', label: 'Montagem de Bombas Hidráulicas - Desde que não haja fundição ou tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município.', grupo: '20.00' },
        { value: '20.13', label: 'Outras atividades não especificadas anteriormente', grupo: '20.00' },
        { value: '21.01', label: 'Fabricação de Artefatos de Alumínio - Desde que não haja fundição ou tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município.', grupo: '21.00' },
        { value: '21.02', label: 'Fabricação de Autopeças para Veículos - Desde que não haja fundição ou tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município.', grupo: '21.00' },
        { value: '21.03', label: 'Fabricação de Componentes para Aerogeradores - Desde que não haja fundição ou tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '21.00' },
        { value: '21.06', label: 'Fabricação de Estruturas e Artefatos Metálicos sem Tratamento de Superfície', grupo: '21.00' },
        { value: '21.07', label: 'Metalurgia de Metais - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '21.00' },
        { value: '21.08', label: 'Metalurgia de Retificação de Peças de Máquinas Industriais', grupo: '21.00' },
        { value: '21.09', label: 'Metalurgia do Pó, inclusive Peças Moldadas/Estamparia - Desde que não haja fundição, a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '21.00' },
        { value: '21.10', label: 'Metalurgia dos Metais Não-Ferrosos, em formas primárias e secundárias, inclusive Ouro - Desde que não haja fundição e os impactos diretos não ultrapassem o território do município', grupo: '21.00' },
        { value: '21.14', label: 'Produção de Laminados / Ligas / Artefatos de Metais Não-Ferrosos sem Tratamento de Superfície', grupo: '21.00' },
        { value: '21.15', label: 'Produção de Soldas e Anodos', grupo: '21.00' },
        { value: '21.16', label: 'Relaminação de Metais Não-Ferrosos, inclusive Ligas', grupo: '21.00' },
        { value: '21.20', label: 'Tratamento de Metais - Desde que não haja tratamento de superfície (Ex: banhos químicos, jateamento, entre outros), a atividade não é capaz de gerar impactos que ultrapassem os limites do município', grupo: '21.00' },
        { value: '21.21', label: 'Outras atividades não especificadas anteriormente', grupo: '21.00' },
        { value: '22.02', label: 'Fabricação de Artefatos de Fibra Sintética', grupo: '22.00' },
        { value: '22.04', label: 'Fabricação de Concentrados Aromáticos Naturais, Artificiais e Sintéticos', grupo: '22.00' },
        { value: '22.05', label: 'Fabricação de Domissanitários: Desinfetantes, Saneantes, Inseticidas, Germicidas e Fungicidas', grupo: '22.00' },
        { value: '22.06', label: 'Fabricação de Espuma de Baixa Densidade', grupo: '22.00' },
        { value: '23.08', label: 'Fabricação de Fios de Borracha e Látex Sintéticos', grupo: '23.00' },
        { value: '23.10', label: 'Fabricação de Perfumarias e Cosméticos', grupo: '23.00' },
        { value: '22.12', label: 'Fabricação de Preparados para Limpeza e Polimento', grupo: '22.00' },
        { value: '22.15', label: 'Fabricação de Produtos Farmacêuticos e Veterinários', grupo: '22.00' },
        { value: '22.16', label: 'Fabricação de Produtos Químicos para Borracha', grupo: '22.00' },
        { value: '22.17', label: 'Fabricação de Produtos Químicos para Calçados', grupo: '22.00' },
        { value: '22.19', label: 'Fabricação de Resinas, Fibras e Fios Artificiais e Sintéticos', grupo: '22.00' },
        { value: '22.20', label: 'Fabricação de Sabão e Detergentes', grupo: '22.00' },
        { value: '22.21', label: 'Fabricação de Velas', grupo: '22.00' },
        { value: '22.22', label: 'Fabricação de Solventes Secantes e Graxas', grupo: '22.00' },
        { value: '22.23', label: 'Fabricação de Tinta em Pó, Solventes e Corantes', grupo: '22.00' },
        { value: '22.24', label: 'Fabricação de Tintas, Adesivos, Vernizes, Esmaltes, Lacas e Impermeabilizantes', grupo: '22.00' },
        { value: '22.25', label: 'Indústria de Fabricação de Concentrados de Cor para Plásticos', grupo: '22.00' },
        { value: '22.27', label: 'Indústria de Recuperação de Extintores de Incêndio', grupo: '22.00' },
        { value: '22.30', label: 'Produção de Óleos / Gorduras e Ceras Vegetais e Animais', grupo: '22.00' },
        { value: '22.31', label: 'Produção de Óleos Essenciais, Vegetais e Produtos Similares, da Destilação da Madeira', grupo: '22.00' },
        { value: '22.32', label: 'Produção de Substâncias e Fabricação de Produtos Químicos', grupo: '22.00' },
        { value: '22.33', label: 'Produção de Argamassa e Massa de Reboco Especiais para Construção Civil', grupo: '22.00' },
        { value: '22.38', label: 'Reembalagem de Produtos Químicos (Soda Cáustica)', grupo: '22.00' },
        { value: '22.41', label: 'Outras atividades não especificadas anteriormente', grupo: '22.00' },
        { value: '23.01', label: 'Beneficiamento de Fibras Têxteis', grupo: '23.00' },
        { value: '23.02', label: 'Confecções', grupo: '23.00' },
        { value: '23.03', label: 'Fabricação de Artigos de Cama, Mesa e Banho', grupo: '23.00' },
        { value: '23.04', label: 'Fabricação de Calçados, Cintos e Bolsas e seus Componentes', grupo: '23.00' },
        { value: '23.05', label: 'Fabricação de Entretelas e Colarinhos', grupo: '23.00' },
        { value: '23.06', label: 'Fabricação de Estofados', grupo: '23.00' },
        { value: '23.07', label: 'Fabricação de Etiquetas, Fitas Têxteis, Zíper, Elásticos e seus componentes', grupo: '23.00' },
        { value: '23.08', label: 'Fabricação de Sandálias e Solas para Calçados', grupo: '23.00' },
        { value: '23.09', label: 'Fiação de Algodão - sem tingimento', grupo: '23.00' },
        { value: '23.10', label: 'Fiação e Tecelagem - sem tingimento', grupo: '23.00' },
        { value: '23.11', label: 'Indústria Têxtil - com tingimento', grupo: '23.00' },
        { value: '23.12', label: 'Malharia, Tinturaria/Tingimento, Acabamento e Estamparia', grupo: '23.00' },
        { value: '23.13', label: 'Outros Acabamentos em peças do Vestuário e Artigos Diversos de Tecidos', grupo: '23.00' },
        { value: '23.14', label: 'Fabricação de Redes', grupo: '23.00' },
        { value: '23.15', label: 'Outras atividades não especificadas anteriormente', grupo: '23.00' },
        { value: '24.01', label: 'Produção/Beneficiamento de Vidros e Similares', grupo: '24.00' },
        { value: '24.02', label: 'Fabricação de Artefatos de Cimento/Concreto', grupo: '24.00' },
        { value: '24.03', label: 'Fabricação de Artefatos de Fibra de Vidro', grupo: '24.00' },
        { value: '24.04', label: 'Fabricação de Colchões', grupo: '24.00' },
        { value: '24.05', label: 'Fabricação de Giz Escolar', grupo: '24.00' },
        { value: '24.06', label: 'Fabricação de Isolantes Térmicos', grupo: '24.00' },
        { value: '24.07', label: 'Fabricação de Lentes', grupo: '24.00' },
        { value: '24.08', label: 'Fabricação de Semi-Jóias (Bijouterias) - sem banho', grupo: '24.00' },
        { value: '24.09', label: 'Fabricação de Semi-Jóias (Bijouterias) - com banho', grupo: '24.00' },
        { value: '24.10', label: 'Gráficas e Editoras', grupo: '24.00' },
        { value: '24.11', label: 'Produção de Emulsões Asfálticas', grupo: '24.00' },
        { value: '24.12', label: 'Produção de Mistura Asfáltica', grupo: '24.00' },
        { value: '24.13', label: 'Usina de Asfalto', grupo: '24.00' },
        { value: '24.14', label: 'Usina de Produção de Concreto', grupo: '24.00' },
        { value: '24.15', label: 'Usina Móvel de Areia Asfáltica usinada a quente ou Usina de Asfalto Móvel', grupo: '24.00' },
        { value: '24.16', label: 'Outras atividades não especificadas anteriormente', grupo: '24.00' },
        { value: '25.01', label: 'Áreas para Reassentamentos Humanos Urbanos', grupo: '25.00' },
        { value: '25.02', label: 'Implantação de Equipamentos Sociais', grupo: '25.00' },
        { value: '25.03', label: 'Projetos Urbanísticos/Paisagísticos diversos', grupo: '25.00' },
        { value: '25.04', label: 'Requalificação Urbana', grupo: '25.00' },
        { value: '25.05', label: 'Balneário', grupo: '25.00' },
        { value: '25.06', label: 'Polo de lazer', grupo: '25.00' },
        { value: '25.07', label: 'Implantação de Praça Pública, Ginásio Poliesportivo, Areninhas e Campo de Futebol', grupo: '25.00' },
        { value: '25.08', label: 'Estádio de Futebol', grupo: '25.00' },
        { value: '25.09', label: 'Outras atividades não especificadas anteriormente', grupo: '25.00' },
        { value: '26.03', label: 'Passagem Molhada sem Barramento de Recurso Hídrico', grupo: '26.00' },
        { value: '26.05', label: 'Pontilhões, Pontes e Túnel', grupo: '26.00' },
        { value: '26.08', label: 'Vias terrestres urbanas e rurais - Manutenção e Restauração - Exceto quando atingir mais de um município.', grupo: '26.00' },
        { value: '26.09', label: 'Outras atividades não especificadas anteriormente', grupo: '26.00' },
        { value: '27.01', label: 'Estação de Tratamento de Água (ETA Convencional)', grupo: '27.00' },
        { value: '27.02', label: 'Estação de Tratamento de Água com simples desinfecção ou sem adição de coagulantes e correlatos com filtração seguida de desinfecção', grupo: '27.00' },
        { value: '27.03', label: 'Sistema de Abastecimento de Água com simples desinfecção ou sem adição de coagulantes e correlatos com filtração seguida de desinfecção', grupo: '27.00' },
        { value: '27.04', label: 'Sistema de Abastecimento de Água com ETA Convencional', grupo: '27.00' },
        { value: '27.07', label: 'Estação Elevatória de Esgoto (EEE) com Tratamento Preliminar', grupo: '27.00' },
        { value: '27.08', label: 'Implantação de banheiros químicos', grupo: '27.00' },
        { value: '27.09', label: 'Outras atividades não especificadas anteriormente', grupo: '27.00' },
        { value: '29.03', label: 'Implantação de sistema adutor', grupo: '29.00' },
        { value: '29.07', label: 'Desassoreamento de corpos hídricos secos (açudes, lagos, lagoas, rios e riachos) - Exceto em rios e riachos', grupo: '29.00' },
        { value: '29.08', label: 'Outras atividades não especificadas anteriormente', grupo: '29.00' },
        { value: '30.01', label: 'Barraca de Praia', grupo: '30.00' },
        { value: '30.02', label: 'Complexo Turístico e de Lazer, inclusive Parques Temáticos', grupo: '30.00' },
        { value: '30.03', label: 'Hotéis', grupo: '30.00' },
        { value: '30.04', label: 'Pousadas, Hospedarias', grupo: '30.00' },
        { value: '30.05', label: 'Centro de Eventos, Culturais, Congressos e Convenções e/ou Feiras', grupo: '30.00' },
        { value: '30.07', label: 'Jardins Botânicos', grupo: '30.00' },
        { value: '30.08', label: 'Outras atividades não especificadas anteriormente', grupo: '30.00' }
    ]
}