import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import FormItbi from '../form-itbi/formItbi';
import { IbgeService } from 'src/app/app-core/lib/ibge.service';
import { CepService } from 'src/app/app-core/lib/cep.service';

@Component({
	selector: 'app-application-itbi',
	templateUrl: './application-itbi.component.html',
	styleUrls: ['./application-itbi.component.scss'],
	providers: [IbgeService, CepService, FiscoService]
})
export class ApplicationItbiComponent extends FormItbi implements OnInit {

	@Input() applicantDetails;

	public estados: [];
	public municipiosPorEstado: [];
	public form?: FormGroup;
	public checkApplicantDataAcquirer = false;
	public checkApplicantDataTransmitter = false;
	public naturezaDatransacao = [
		"Compra e Venda",
		"Cessão de Posse",
		"Alienação",
		"Alienação Fiduciária",
		"Anuência",
		"Cessão de Direitos",
		"Cessão de Direitos Hereditários",
		"Cessão de Posse e Transferência De Direitos Hereditários",
		"Cisão",
		"Compra e Venda com Anuência",
		"Consolidação da Propriedade",
		"Consolidação de Garantia",
		"Dação em Pagamento",
		"Desapropriação",
		"Direito de Posse",
		"Distrato",
		"Distrato Social",
		"Divorcio Consensual",
		"Incorporação ao Patrimônio",
		"Partilha de Bens",
		"Permuta",
		"Separação de Bens",
		"Separação Judicial Consensual",
		"Transferência de Direito Hereditários",
		"Transmissão de Bens ao Cônjuge",
		"Título de Domínio",
		"Usufruto",
		"Cisão Parcial",
		"Distrato de Doação",
		"Arrematação",
		"Instituição de servidão administrativa",
		"Desincorporação de Bens Imóveis em Razão da Extinção de Empresa",
		"Cessão de transferência",
		"Integralização ao Patrimônio",
		"Cessão de direitos de aquisição",
		"Concessão de Direito Real de Superfície"
	];

	private fieldNames = [
		'SOLIT_TransacaoAreaTerreno',
		'SOLIT_TransacaoAreaTotal',
		'SOLIT_TransacaoComplemento',
		'SOLIT_TransacaoNumero',
		'SOLIT_TransacaoLote',
		'SOLIT_TransmitenteNome',
		'SOLIT_TransmitenteCPF',
		'SOLIT_TransmitenteUF',
		'SOLIT_TransmitenteMunicipio',
		'SOLIT_TransmitenteLogradouro',
		'SOLIT_TransmitenteNumero',
		'SOLIT_TransmitenteBairro',
		'SOLIT_TransmitenteComplemento',
		'SOLIT_TransmitenteCEP',
		'SOLIT_TransacaoQuadra',
		'SOLIT_TransacaoEndereco',
	];

	constructor(
		public fb: FormBuilder,
		public fiscoService: FiscoService,
		public global: GlobalService,
		private ibgeService: IbgeService,
		private cepService: CepService
	) {
		super(fiscoService, global)
		this.form = this.fb.group({
			SOLIT_AdquirenteNome: [''],
			SOLIT_AdquirenteCPF: [''],
			SOLIT_AdquirenteUF: [''],
			SOLIT_AdquirenteMunicipio: [''],
			SOLIT_AdquirenteLogradouro: [''],
			SOLIT_AdquirenteNumero: [''],
			SOLIT_AdquirenteBairro: [''],
			SOLIT_AdquirenteComplemento: [''],
			SOLIT_AdquirenteCEP: [''],
			SOLIT_TransmitenteNome: [''],
			SOLIT_TransmitenteCPF: [''],
			SOLIT_TransmitenteUF: [''],
			SOLIT_TransmitenteMunicipio: [''],
			SOLIT_TransmitenteLogradouro: [''],
			SOLIT_TransmitenteNumero: [''],
			SOLIT_TransmitenteBairro: [''],
			SOLIT_TransmitenteComplemento: [''],
			SOLIT_TransmitenteCEP: [''],
			SOLIT_NaturezaTransacao: [null],
			SOLIT_TransacaoInscricao: [''],
			SOLIT_TransacaoEndereco: [''],
			SOLIT_TransacaoNumero: [''],
			SOLIT_TransacaoComplemento: [''],
			SOLIT_TransacaoLote: [''],
			SOLIT_TransacaoQuadra: [''],
			SOLIT_TransacaoTipoImovel: ['Urbano'],
			SOLIT_TransacaoAreaTerreno: [null],
			SOLIT_TransacaoFracaoIdeal: [''],
			SOLIT_TransacaoAreaPrivada: [null],
			SOLIT_TransacaoImobiliaria: [''],
			SOLIT_TransacaoAreaTotal: [null],
			SOLIT_TransacaoValorFinanciado: [null],
			SOLIT_TransacaoValorNaoFinanciado: [null],
			SOLIT_TransacaoTotalDeclarado: [null],
			formVariants: this.fb.group({
				termCheck: [false]
			})
		});
		this.inscricaoField = 'SOLIT_TransacaoInscricao';
		this.tipoImovelField = 'SOLIT_TransacaoTipoImovel';
		this.enableDisableListField = [
			'SOLIT_TransacaoAreaTerreno',
			'SOLIT_TransacaoAreaTotal',
			'SOLIT_TransacaoComplemento',
			'SOLIT_TransacaoNumero',
			'SOLIT_TransacaoLote',
			'SOLIT_TransacaoQuadra',
			'SOLIT_TransmitenteNome',
			'SOLIT_TransmitenteCPF',
			'SOLIT_TransmitenteLogradouro',
			'SOLIT_TransmitenteNumero',
			'SOLIT_TransmitenteBairro',
			'SOLIT_TransmitenteComplemento',
			'SOLIT_TransmitenteCEP',
			'SOLIT_TransacaoEndereco'
		]
		this.loadUfs();
		this.loadMunicipiosByUF();
		this.handleFindCep();
	}

	ngOnInit() {
		this.disabledFields(this.fieldNames);
		this.disableTransmmiterUFandLocation();
		this.changeDataRequester();
		this.resettingValuesChangingPropertyType();
	}

	private disableTransmmiterUFandLocation() {
		this.form.get('SOLIT_TransmitenteUF').disable();
		this.form.get('SOLIT_TransmitenteMunicipio').disable();
	}

	getAddressAcquirertData(event) {
		this.form?.patchValue({
			SOLIT_AdquirenteMunicipio: event.localidade,
			SOLIT_AdquirenteBairro: event.bairro,
			SOLIT_AdquirenteLogradouro: event.logradouro,
			SOLIT_AdquirenteUF: event.uf,
			SOLIT_AdquirenteNumero: event.numero,
			SOLIT_AdquirenteComplemento: event.complemento,
			SOLIT_AdquirenteCEP: event.CEP
		});
		this.form.updateValueAndValidity();
	}

	public changeDataRequester() {
		this.form?.patchValue({
			SOLIT_AdquirenteCPF: this.applicantDetails.SOLR_CpfCnpj,
			SOLIT_AdquirenteNome: this.applicantDetails.SOLR_NomeRazaoSocial
		});
		this.form.updateValueAndValidity();
	}

	public patchValueformControl(data) {
		const patchValues = {
			SOLIT_TransacaoAreaTerreno: data.imovel_area_terreno,
			SOLIT_TransacaoAreaTotal: data.imovel_area_total,
			SOLIT_TransacaoComplemento: data.imovel_complemento,
			SOLIT_TransacaoNumero: data.imovel_numero,
			SOLIT_TransacaoLote: data.imovel_lote,
			SOLIT_TransacaoQuadra: data.imovel_quadra,
			SOLIT_TransmitenteNome: data.transmitente_nome_razao_social,
			SOLIT_TransmitenteCPF: data.transmitente_cpf_cnpj,
			SOLIT_TransmitenteLogradouro: data.transmitente_logradouro,
			SOLIT_TransmitenteNumero: data.transmitente_numero,
			SOLIT_TransmitenteBairro: data.transmitente_bairro,
			SOLIT_TransmitenteComplemento: data.transmitente_complemento,
			SOLIT_TransmitenteCEP: data.transmitente_cep,
			SOLIT_TransacaoEndereco: data.imovel_logradouro
		};
		return patchValues;
	}

	sumValuesFinanced() {
		const value1 = parseFloat(this.form.get('SOLIT_TransacaoValorFinanciado').value);
		const value2 = parseFloat(this.form.get('SOLIT_TransacaoValorNaoFinanciado').value);
		const sum = (value1 + value2).toFixed(2);
		this.form.get('SOLIT_TransacaoTotalDeclarado').patchValue(sum);
	}

	private loadUfs() {
		this.ibgeService.ufs.subscribe({
			next: (response: any) => {
				this.estados = response;
			}
		});
	}

	private loadMunicipiosByUF() {
		this.form.get('SOLIT_TransmitenteUF').valueChanges.subscribe((value: any) => {
			this.ibgeService.municipiosByUf(value).subscribe({
				next: (response: any) => {
					this.municipiosPorEstado = response;
				}
			});
		})
	}

	private handleFindCep() {
		this.form.get('SOLIT_TransmitenteCEP').valueChanges.subscribe({
			next: (value: string) => {
				const transmitenteCEP = this.form.getRawValue().SOLIT_TransmitenteCEP
				if (transmitenteCEP && transmitenteCEP.length == 8) {
					this.cepService.getEnderecoByCep(transmitenteCEP).subscribe({
						next: (response: any) => {
							this.form.patchValue({
								SOLIT_TransmitenteUF: response.uf,
								SOLIT_TransmitenteMunicipio: response.localidade
							});
							this.form.updateValueAndValidity();
						}
					});
				}
			}
		});
	}

	public eraseMoreFields() {
		this.form?.patchValue({
			SOLIT_TransmitenteUF: null,
			SOLIT_TransmitenteMunicipio: null
		});
		this.form.updateValueAndValidity();
	}
}
