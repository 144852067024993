import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from 'src/app/app-core/services/request.service';
import { Request } from 'src/app/interfaces/request';

@Component({
    selector: 'app-view-public-requests',
    templateUrl: './view-public-requests.component.html',
    styleUrls: ['./view-public-requests.component.scss'],
    providers: [RequestService],
})
export class ViewPublicRequestsComponent {
    public request: Request;
    public forDocuments = [
        {
            name: 'PROCURAÇÃO',
            type: 'procuracao',
        },
        {
            name: 'REQUERIMENTO',
            type: 'requerimento',
        },
        {
            name: 'COMPLEMENTARES',
            type: 'complementar',
        },
    ];

    constructor(
        private _activatedRoute: ActivatedRoute,
        public requestService: RequestService,
        public router: Router,
    ) {
        this._activatedRoute.params.subscribe((params: { id: string }) => {
            if (params.id) {
                let parsedId = parseInt(params.id);
                this.requestService.viewPublicRequest(parsedId).subscribe({
                    next: (response: any) => {
                        this.request = response;
                    },
                });
            }
        });
    }

    public goToHome(): void {
        this.router.navigate(['/principal']);
    }

    mappingDocument(documentos: any, type: string): any[] {
        return documentos.filter((documento) => documento.SOLA_Tipo === type);
    }
}
