import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FiscoService {

  private url = environment.fiscoURL;

  constructor(private http: HttpClient) { }

  public getDataInscricao(inscricao: string, tipoImovel: string): Observable<Object> {
    return this.http.get(`${this.url}/get-imovel-data/${inscricao}/${tipoImovel}`);
  }

  public getDataCnae(codigo: string): Observable<Object> {
    return this.http.get(`${this.url}/get-atividade/${codigo}`);
  }

  public getDataInscricaoMunicipal(inscricao: string): Observable<Object> {
    return this.http.get(`${this.url}/get-data-inscricao-municipal/${inscricao}`);
  }

}
