import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { GlobalService } from './app-core/lib/global.service';
import { CompanyService } from './app-core/services/company.service';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

import { ViewPublicProccessComponent } from './pages/view-public-proccess/view-public-proccess.component';
import { RequestsModule } from './app-core/modules/requests/requests.module';
import { HelpGuideComponent } from './pages/help-guide/help-guide.component';
import { ServiceComponent } from './pages/service/service.component';
import { SearchProcessComponent } from './pages/search-process/search-process.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from 'primeng/api';
import { ProcessModule } from './app-core/modules/proccess/process.module';
import { AuditModule } from './app-core/modules/audit/audit.module';
import { ProcessFeedbackComponent } from './pages/process-feedback/process-feedback.component';
import { InternalProcessFeedbackComponent } from './pages/internal-process-feedback/internal-process-feedback.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { BlockUIModule } from 'ng-block-ui';

registerLocaleData(localePt);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        CommonModule,
        RequestsModule,
        ProcessModule,
        PaginatorModule,
        TableModule,
        DialogModule,
        ToastModule,
        SharedModule,
        AuditModule,
        BlockUIModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        LandingPageComponent,
        ViewPublicProccessComponent,
        HelpGuideComponent,
        ServiceComponent,
        SearchProcessComponent,
        ProcessFeedbackComponent,
        InternalProcessFeedbackComponent,
        HomePageComponent,
    ],
    providers: [GlobalService, CompanyService, { provide: LOCALE_ID, useValue: 'pt-BR' }, DatePipe],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {
    constructor(
        public global: GlobalService,
        public empresa: CompanyService,
        public router: Router,
    ) {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this.global.startLoading();
            }
            if (evt instanceof NavigationEnd) {
                this.global.stopLoading();
            }
        });

        let host = window.location.hostname;
        if (host === 'localhost') {
            //host = 'gcommerce.com.br';
            //host = 'newlife.gcommerce.com.br';
            //host = 'agendaclass.com.br';
            host = 'https://app-credinowe.herokuapp.com/';
        }

        this.global.theme.loading = true;
        this.empresa.getThemeByURL(host).subscribe((response: any) => {
            if (response.success) {
                GlobalService.companyID = response.companyID;
                this.global.setTheme(response.data);
            } else {
                this.global.setTheme(this.global.theme);
            }
            this.global.theme.loading = false;
        });
    }
}
