import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CadComponent } from './layout/cad.component';
import { AppCoreRoutingModule } from './app-core.routing';
import { TesteComponent } from './modules/teste/teste.component';
import { ModalComponent } from './layout/modal.component';
import { GerinusToolsModule } from './lib/gerinus-tools.module';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { AdminLayoutModule } from '../layouts/admin-layout/admin-layout.module';
import { UploadService } from './services/upload.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        NgbModalModule,
        AppCoreRoutingModule,
        GerinusToolsModule,
        SharedModule,
        ComponentsModule,
        AdminLayoutModule,
    ],
    declarations: [DashboardComponent, ModalComponent, TesteComponent],
    providers: [UploadService],
    exports: [
        GerinusToolsModule,
        SharedModule,
        AppCoreRoutingModule,
        DashboardComponent,
        ComponentsModule,
        ModalComponent,
        TesteComponent,
    ],
})
export class AppCoreModule { }
