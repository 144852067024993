import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CepService } from 'src/app/app-core/lib/cep.service';
import { IbgeService } from 'src/app/app-core/lib/ibge.service';

@Component({
  selector: 'app-endereco-form',
  templateUrl: './endereco-form.component.html',
  styleUrls: ['./endereco-form.component.scss'],
  providers: [IbgeService, CepService]
})
export class EnderecoFormComponent {

  public form: FormGroup;
  public estados: [];
  public municipiosPorEstado: [];

  @Output() recarregarComponente: EventEmitter<void> = new EventEmitter<void>();
  @Output() private onEnderecoChange = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private ibgeService: IbgeService,
    private cepService: CepService
  ) {
    this.initForm();
    this.handleFindCep();
    this.formChanges();
    this.loadUfs();
    this.loadMunicipiosByUF();
  }

  private loadUfs() {
    this.ibgeService.ufs.subscribe({
      next: (response: any) => {
        this.estados = response;
      }
    });
  }

  public loadMunicipiosByUF() {
    this.form.get('uf').valueChanges.subscribe((value: any) => {
      this.ibgeService.municipiosByUf(value).subscribe({
        next: (response: any) => {
          this.municipiosPorEstado = response;
        }
      });
    })
  }

  private handleFindCep() {
    this.form.get('CEP').valueChanges.subscribe({
      next: (value: string) => {
        if (value.length > 7) {
          this.cepService.getEnderecoByCep(value).subscribe({
            next: (response: any) => {
              console.log('[endereco-form.handleFindCep] response: ', response);
              this.form.patchValue({
                bairro: response.bairro,
                logradouro: response.logradouro,
                uf: response.uf,
                localidade: response.localidade
              });
              this.form.updateValueAndValidity();
            }
          });
        }
      }
    });
  }

  private initForm() {
    this.form = this.fb.group({
      uf: ['', Validators.required],
      localidade: ['', Validators.required],
      logradouro: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      CEP: ['', Validators.required]
    });
  }

  private formChanges() {
    this.form.valueChanges.subscribe(() => {
      this.onEnderecoChange.emit(this.form.getRawValue());
    });
  }

  reset() {
    this.form.patchValue({
      uf: '',
      localidade: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      CEP: ''
    });
    this.form.updateValueAndValidity();
  }

}
