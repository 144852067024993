export enum ProcessStatusEnum {
    NEW = 1,
    IN_PROGRESS = 2,
    ARCHIVED = 3,
    CONCLUDED = 4,
    IN_CORRECTION = 5,
    OUTDATED = 6,
    CANCELED = 7,
    ANSWERED = 8
}
