import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class ProcessEvaluationService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'processo_avaliacao';
        this.idField = 'PRA_CodigoAvaliacao';
        this.model = 'ProcessoAvaliacaoModel';
    }
}
