import { AfterViewInit, Component } from '@angular/core';
import FormRequest from '../common/formRequest';
import { FormBuilder, Validators } from '@angular/forms';
import { ApplicantSEPLAGTypeEnum } from 'src/app/Enums/applicantSEPLAGTypeEnum';
import { AutoridadeEnum } from 'src/app/Enums/autoridadeTypeEnum';
import { FiscoService } from 'src/app/app-core/services/fisco.service';

@Component({
  selector: 'app-form-request-seplag',
  templateUrl: './form-request-seplag.component.html',
  styleUrls: ['./form-request-seplag.component.scss']
})
export class FormRequestSeplagComponent extends FormRequest implements AfterViewInit {

  public checkBoxInputs: { label: string, value: string }[] = [
    {
      label: 'SERVIDOR(A)',
      value: ApplicantSEPLAGTypeEnum.SERVER
    },
    {
      label: 'BENEFICIÁRIO(A)',
      value: ApplicantSEPLAGTypeEnum.RECIPIENT
    },
  ];

  private requiredFieldsForServer = [
    "SOLS_RequerenteRG",
    "SOLS_RequerenteRGOrgaoExpeditor",
    "SOLS_RequerenteTelefone",
    "SOLS_RequerenteDataNascimento",
    "SOLS_RequerenteCargo",
    "SOLS_RequerenteLotacao",
  ];

  private requiredFieldsForRecipient = [
    "SOLS_RequerenteNome",
    "SOLS_RequerenteCPF",
    "SOLS_RequerenteParentescoExServidor",
  ];

  public autoridades: { label: string, value: string }[] = [
    {
      label: "Secretário(a) de Infraestrutura",
      value: AutoridadeEnum.SECRETARIO_INFRAESTRUTURA
    },
    {
      label: "Secretário(a) de Educação",
      value: AutoridadeEnum.SECRETARIO_EDUCACAO
    },
    {
      label: "Secretário(a) Regional Pecém",
      value: AutoridadeEnum.SECRETARIO_REGEGIONAL_PECEM,

    },
    {
      label: "Prefeito Municipal",
      value: AutoridadeEnum.PREFEITO_MUNICIPAL
    },
    {
      label: "Ouvidor(a)",
      value: AutoridadeEnum.OUVIDOR
    },
    {
      label: "Secretário(a) de Saúde",
      value: AutoridadeEnum.SECRETARIO_SAUDE
    },
    {
      label: "Controlador(a)",
      value: AutoridadeEnum.CONTROLADOR
    },
    {
      label: "Secretário(a) de Administração",
      value: AutoridadeEnum.SECRETARIO_ADMINISTRACAO
    },
    {
      label: "Secretário(a) de Governo",
      value: AutoridadeEnum.SECRETARIO_GOVERNO
    },
    {
      label: "Secretário(a) de Trab. e Des.Social",
      value: AutoridadeEnum.SECRETARIO_TRABALHO_SOCIAL
    },
    {
      label: "Procurador(a) Geral",
      value: AutoridadeEnum.PROCURADOR_GERAL
    },
    {
      label: "Secretário(a) Geral",
      value: AutoridadeEnum.SECRETARIO_GERAL
    },
    {
      label: "Secretário(a) de Finanças",
      value: AutoridadeEnum.SECRETARIO_FINANCAS
    },
    {
      label: "Coordenador(a) do FMPS",
      value: AutoridadeEnum.COORDENADOR_FMPS
    },
    {
      label: "Outros",
      value: AutoridadeEnum.OUTROS
    }
  ];

  constructor(
    public fb: FormBuilder,
    public fiscoService: FiscoService
  ) {
    super(fb, fiscoService);
    this.initForm();
    this.onRequerenteTipoChange();
    this.handleDisableCheckBox();
    this.setRequestDescription();
    this.formChanges();
  }

  ngAfterViewInit(): void {
    /* Elemento check box vir selecionado com a opção Servidor. */
    this.setCheckbox(ApplicantSEPLAGTypeEnum.SERVER, true);
  }

  public setRequerenteTipo(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.requerenteFormControl.setValue(checkbox.value);
  }

  public setUFAndStateOnForm(event: { uf: string, estado: string }) {
    this.form.get('SOLS_RequerenteUF').setValue(event.uf);
    this.form.get('SOLS_RequerenteMunicipio').setValue(event.estado);
  }

  public setEndereco(event: { logradouro: string, numero: string, complemento: string, bairro: string, CEP: string, uf: string, localidade: string }) {
    this.form.get('SOLS_RequerenteLogradouro').setValue(event.logradouro);
    this.form.get('SOLS_RequerenteNumero').setValue(event.numero);
    this.form.get('SOLS_RequerenteComplemento').setValue(event.complemento);
    this.form.get('SOLS_RequerenteBairro').setValue(event.bairro);
    this.form.get('SOLS_RequerenteCEP').setValue(event.CEP);
    this.form.get('SOLS_RequerenteUF').setValue(event.uf);
    this.form.get('SOLS_RequerenteMunicipio').setValue(event.localidade);
  }

  public get isServidor(): boolean {
    return this.requerenteFormControl.value === ApplicantSEPLAGTypeEnum.SERVER;
  }

  public get isBeneficiario(): boolean {
    return this.requerenteFormControl.value === ApplicantSEPLAGTypeEnum.RECIPIENT;
  }

  private handleDisableCheckBox() {
    this.requerenteFormControl.valueChanges.subscribe((value) => {
      if (value === ApplicantSEPLAGTypeEnum.SERVER) {
        this.setCheckbox(ApplicantSEPLAGTypeEnum.RECIPIENT, false);
      }

      if (value === ApplicantSEPLAGTypeEnum.RECIPIENT) {
        this.setCheckbox(ApplicantSEPLAGTypeEnum.SERVER, false);
      }
    });
  }

  private initForm() {
    this.form = this.fb.group({
      // Campos gerais
      SOLS_Descricao: ['', Validators.required],
      termoAceito: [null, Validators.requiredTrue],
      SOLS_RequerenteTipo: [ApplicantSEPLAGTypeEnum.SERVER, Validators.required],

      // Campos comuns a ambos os tipos de requerente
      SOLS_RequerenteRG: ['', Validators.required],
      SOLS_RequerenteUF: ['', Validators.required],
      SOLS_RequerenteMunicipio: ['', Validators.required],
      SOLS_RequerenteLogradouro: ['', Validators.required],
      SOLS_RequerenteNumero: ['', Validators.required],
      SOLS_RequerenteBairro: ['', Validators.required],
      SOLS_RequerenteCEP: ['', Validators.required],
      SOLS_RequerenteAutoridadeRequerida: ['', Validators.required],

      // Campos específicos para requerente Servidor
      SOLS_RequerenteRGOrgaoExpeditor: ['', Validators.required],
      SOLS_RequerenteTelefone: ['', Validators.required],
      SOLS_RequerenteDataNascimento: ['', Validators.required],
      SOLS_RequerenteCargo: ['', Validators.required],
      SOLS_RequerenteLotacao: ['', Validators.required],
      SOLS_RequerenteUnidadeExercicio: [''],
      SOLS_RequerenteDataExercicio: [''],
      SOLS_RequerenteComplemento: [''],
      SOLS_RequerenteClasse: [''],

      // Campos específicos para requerente Beneficiário
      SOLS_RequerenteNome: [''],
      SOLS_RequerenteCPF: [''],
      SOLS_RequerenteParentescoExServidor: [''],
      SOLS_RequerenteDataObito: [''],

      formVariants: this.fb.group({
        SOL_Descricao: ["", Validators.required]
      })
    });
  }

  private onRequerenteTipoChange(): void {
    this.requerenteFormControl.valueChanges.subscribe((value) => {
      if (value === ApplicantSEPLAGTypeEnum.SERVER) {
        this.setRequiredAndDesableFields(this.requiredFieldsForServer, this.requiredFieldsForRecipient);
      }

      if (value === ApplicantSEPLAGTypeEnum.RECIPIENT) {
        this.setRequiredAndDesableFields(this.requiredFieldsForRecipient, this.requiredFieldsForServer);
      }
    });
  }

  private setRequiredAndDesableFields(fields: string[], fieldsToDisable: string[]) {
    for (const field of fields) {
      this.form.get(field).setValidators(Validators.required);
    }

    for (const field of fieldsToDisable) {
      this.form.get(field).setValue(null);
      this.form.get(field).clearValidators();
      this.form.get(field).updateValueAndValidity();
    }
  }

  private get requerenteFormControl() {
    return this.form.get('SOLS_RequerenteTipo')
  }

  private setCheckbox(elementId: string, value: boolean) {
    var element = document.getElementById(elementId) as HTMLInputElement;
    element.checked = value;
  }

  private setRequestDescription() {
    this.form.get("SOLS_Descricao").valueChanges.subscribe((response: string) => {
      this.form.get("formVariants").get("SOL_Descricao").patchValue(response)
    })
  }
}
