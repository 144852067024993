import { Directive, ElementRef, Input } from '@angular/core';
import { GlobalService } from '../global.service';

@Directive({
    selector: '[cloudImg]',
})
export class CloudImgDirective {
    @Input() src;
    @Input() backgroundImage;

    constructor(
        public el: ElementRef,
        public global: GlobalService,
    ) {
        el.nativeElement.onerror = (error) => {
            // el.nativeElement.src = 'assets/img/brand/no-image.png'
            // console.log("[CloudImgDirective] Error",error);
        };
    }
}
