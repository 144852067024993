import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { DepartmentService } from 'src/app/app-core/services/department.service';
import { PersonService } from 'src/app/app-core/services/person.service';
import { ProccessHistoryService } from 'src/app/app-core/services/proccessHistorory.service';
import { UserService, UsersGroupEnum } from 'src/app/app-core/services/user.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { ProccessCommentService } from 'src/app/app-core/services/proccessComment.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { MessageService } from 'primeng/api';

import swal from 'sweetalert2';
import { formatDistanceToNow } from 'date-fns';
import { id, ptBR } from 'date-fns/locale';
import { IProccess, ISubmitExtendProcessSchema, ISendRequesterConsiderationsSchema, ICancellationEvaluationSchema, IRequestToCancelProcessSchema, IProcessCorrections } from 'src/app/interfaces/proccess.interface';
import { IDepartment } from 'src/app/interfaces/department';
import { IPerson } from 'src/app/interfaces/person';
import { IProcessComment } from 'src/app/interfaces/proccessComment';
import { IProccessHistory } from 'src/app/interfaces/proccessHistory';
import { IListScopeCommentsResponse } from 'src/app/interfaces/listScope/listScopeCommentResponse';
import { IListScopeHistoryProcessResponse } from 'src/app/interfaces/listScope/listScopeHistoryProccessResponse';
import { IListScopeDepartmentResponse } from 'src/app/interfaces/listScope/listScopeDepartmentResponse';
import { IGetEntityProccessResponse } from 'src/app/interfaces/getEntity/getEtityProccessResponse';
import { UploadService } from 'src/app/app-core/services/upload.service';
import { ProcessCorrectionService } from 'src/app/app-core/services/process-correction.service';
import Swal from 'sweetalert2';
import { IChangeRequestDescriptionSchema } from 'src/app/interfaces/request';
import { ProcessStatusEnum } from 'src/app/Enums/ProcessStatusEnum';
import { HttpClient } from '@angular/common/http';

interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

@Component({
    selector: 'app-view-process',
    templateUrl: './view-process.component.html',
    styleUrls: ['./view-process.component.scss'],
    providers: [
        ProcessService,
        DepartmentService,
        PersonService,
        ProccessHistoryService,
        UserService,
        RequestService,
        ProccessCommentService,
        UploadService,
        ProcessCorrectionService,
        MessageService,
    ],
})
export class ViewProcessComponent extends GerinusBD implements OnInit {
    public hasComplements: boolean = false;
    public process: IProccess;
    public swal = swal;
    public modalName: string = 'tramitar';
    public forDocuments = [
        {
            name: 'PROCURAÇÃO',
            type: 'procuracao',
        },
        {
            name: 'REQUERIMENTO',
            type: 'requerimento',
        },
        {
            name: 'COMPLEMENTARES',
            type: 'complementar',
        },
    ];
    public departments: IDepartment[];
    public persons: IPerson[];
    public comments: IProcessComment[] = [];
    public histories: IProccessHistory[] = [];
    public commentText: string = '';
    public USR_foto: string;
    public allowedFileMimeTypes: string[] = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/x-zip-compressed',
        'application/x-rar-compressed',
        'image/jpg',
        'image/jpeg',
        'image/png',
    ];
    public showDropzonePreview: boolean = true;

    public encryptedId: string;
    public tipoTramitacao: string;
    public responsibles: any;
    public selectedResponsible: any;
    public sidebarVisible: boolean = false;
    public reasonReturn: string;
    public daysAmountExtendsProcess: number;
    public editDescriptionAllowed: boolean = false;
    public isEditingDescription = false;
    public requesterConsiderations: string;
    public corrections: IProcessCorrections[];

    public alreadyFilteredList = {}
    public modalResponsible: string = 'Rejeitar'
    isCancelProcessDialogOpen: boolean = false;
    isArchiveNoteModalOpen: boolean = false;
    responsibleRequest: any;

    constructor(
        public processService: ProcessService,
        public route: ActivatedRoute,
        public router: Router,
        public global: GlobalService,
        public departmentService: DepartmentService,
        public personService: PersonService,
        public processHistoryService: ProccessHistoryService,
        public userService: UserService,
        public requestService: RequestService,
        private commentService: ProccessCommentService,
        private cryptoService: CryptoService,
        private uploadService: UploadService,
        private messageService: MessageService,
    ) {
        super(global, processService);
        this.showEmptyData = false;
        this.showHeader = false;
        this.showPaginate = false;

        this.afterCreate();
        this.add();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {

            try {
                this.encryptedId = params.get('id');
                const processoId = Number(this.cryptoService.decrypt(this.encryptedId));

                this.initializeData(processoId);

                if (!processoId) {
                    this.handleRedirect();
                }
            } catch (error) {
                this.handleRedirect();
            }
        });
    }

    private handleRedirect(): void {
        if (this.userService.isTriagem()) {
            this.router.navigate(['/process/all'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isDiretorDepartamento() || this.userService.isMembroDepartamento()) {
            this.router.navigate(['/process/sector'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isCidadao()) {
            this.router.navigate(['/requests/processes'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isAdmOrgao() || this.userService.isSecretario()) {
            this.router.navigate(['/process/organization'], { queryParams: { 'not-found': true } });
        }
    }

    private initializeData(processoId: number) {
        this.getProcess(processoId);
        this.getPerson();
        this.getHistories(processoId);
        this.getComments(processoId);
        this.searchResponsibles();
        this.getResponsibleRequests(processoId)
        this.getCorrections(processoId);
    }

    private get _haveDispatch(): boolean {
        return this.process.despachos && this.process.despachos.length > 0;
    }

    public defaults() {
        return {
            documentos: {
                parecerDespacho: [],
                correctionFiles: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            tramitacao: {
                PRCH_Historico: '',
                NTR_Descricao: '',
            },
            comentario: {
                COM_Descricao: '',
                COM_CodigoUsuario: this.global.user().USR_CodigoUsuario,
                COM_CodigoProcesso: '',
            },
            despacho: {
                PDES_Descricao: '',
                PDES_CodigoUsuario: this.global.user().USR_CodigoUsuario,
            },
            processAttachments: {
                complementaryFiles: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            processCertificates: {
                certificates: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            fixedAttachments: {
                documents: []
            },
            cancelProcess: {
                reasonCancel: '',
                evaluationDescription: ''
            },
            archive: {
                archiveNote: ''
            }
        };
    }

    getPerson() {
        this.personService
            .listAll(
                `
                    "PE_CodigoDepartamento" = ${this.global.user().PE_CodigoDepartamento} and
                    "PE_CodigoPessoa" != ${this.global.user().PE_CodigoPessoa}
                `,
                0,
            )
            .subscribe({
                next: (response: any) => {
                    this.persons = response.data;
                },
            });
    }

    getHistories(processoId: number) {
        this.processHistoryService
            .listAll(`"PRCH_CodigoProcesso" = ${processoId} order by "PRCH_Data" desc`)
            .subscribe({
                next: (response: IListScopeHistoryProcessResponse) => {
                    this.histories = response.data;
                },
            });
    }

    isClickInsideComponent(event: MouseEvent, componentName: string): boolean {
        const clickedComponent = event.target as HTMLElement;

        return (
            clickedComponent.classList.contains(componentName) || clickedComponent.closest(`.${componentName}`) !== null
        );
    }

    getComments(processId: number) {
        this.commentService
            .listAll(`"COM_CodigoProcesso" = ${processId} order by "COM_DataComentario" desc`, 0)
            .subscribe({
                next: (response: IListScopeCommentsResponse) => {
                    this.comments = response.data.map((comment) => {
                        return {
                            ...comment,
                            formattedDate: formatDistanceToNow(new Date(comment.COM_DataComentario), {
                                addSuffix: true,
                                locale: ptBR,
                            }),
                        };
                    });
                },
            });
    }

    getDepartments() {
        this.departmentService
            .listAll(
                `
                "DEP_CodigoOrgao" = ${this.process.SOL_CodigoOrgao} and
                "DEP_CodigoDepartamento" != ${this.process.PRT_DepartamentoAtual}
                order by "DEP_Descricao" asc
                `,
                0,
            )
            .subscribe({
                next: (response: IListScopeDepartmentResponse) => {
                    this.departments = response.data;
                },
                error: (error) => {
                    console.log('ERRO', error);
                },
            });
    }

    getProcess(id: number) {
        this.processService.getEntity(id).subscribe({
            next: (response: IGetEntityProccessResponse) => {
                this.process = response.entity;
                this.process.PRC_DataRecebimento = new Date(this.process.PRC_DataRecebimento);
                this.process.PRC_DataRecebimento.setHours(this.process.PRC_DataRecebimento.getHours() + 3);

                this.hasComplements = this.process.documentos.some(
                    (documento) => documento.SOLA_Tipo == 'complementar',
                );
                this.getDepartments();
                this.getPerson();

                document.querySelector('title').text = 'Processo N° ' + this.process.PRC_NumeroProcesso;
            },
        });
    }

    openExtendProcessModal(): void {
        this.global.showModalInfo('prorrogar-processo');
    }

    handleExtendsProcess(): void {
        const extendsProcessData: ISubmitExtendProcessSchema = {
            processId: this.process.PRC_CodigoProcesso,
            daysAmount: this.daysAmountExtendsProcess
        }

        this.processService.submitExtendsProcess(extendsProcessData).subscribe({
            next: (response: any) => {
                this.getProcess(this.process.PRC_CodigoProcesso);
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O processo foi prorrogado com sucesso.',
                });
                this.global.closeModalInfo('prorrogar-processo');
            }
        })
    }

    handleCompleteProcess(): void {
        if (!this._haveDispatch) {
            this.showMissingDispatchAlert('conclusao');
        } else {
            const completeProcessData = {
                PRCH_Historico: `${this.global.user().PE_Nome} concluiu o processo!`,
            };

            this.confirmProcessAction('conclusao', () => {
                this.processService
                    .markProcessAsCompleted(this.process.PRC_CodigoProcesso, completeProcessData)
                    .subscribe({
                        next: async () => {
                            await this.getProcess(this.process.PRC_CodigoProcesso);

                            this.messageService.add({
                                severity: 'success',
                                icon: 'ph ph-check-circle',
                                closable: true,
                                summary: 'Sucesso!',
                                detail: 'O processo foi marcado como concluído.',
                            });
                        },
                    });
            });
        }
    }

    handleArchiveProcess(): void {
        const archiveProcessData = {
            PRCH_Historico: `${this.global.user().PE_Nome} arquivou o processo!`,
            archiveNote: this.entity.archive.archiveNote
        };

        this.processService
            .markProcessAsArchived(this.process.PRC_CodigoProcesso, archiveProcessData)
            .subscribe({
                next: async () => {
                    await this.getProcess(this.process.PRC_CodigoProcesso);
                    this.isArchiveNoteModalOpen = false
                    this.messageService.add({
                        severity: 'success',
                        icon: 'ph ph-check-circle',
                        closable: true,
                        summary: 'Sucesso!',
                        detail: 'O processo foi marcado como arquivado.',
                    });
                },
            });
    }

    handleSaveDispatch() {
        this.processService.addDispatch(this.process.PRC_CodigoProcesso, this.entity.despacho).subscribe({
            next: () => {
                this.entity.despacho.PDES_Descricao = '';

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Novo despacho adicionado.',
                });

                this.getProcess(this.process.PRC_CodigoProcesso);
            },
        });
    }

    handleAddResponsible() {
        const addResponsibleData = {
            PRC_CodigoResponsavel: this.selectedResponsible.PE_CodigoPessoa,
            PRCH_Historico: `${this.global.user().PE_Nome} adicionou ${this.selectedResponsible.PE_Nome
                } como responsável do processo`,
        };

        this.processService.addResponsible(this.process.PRC_CodigoProcesso, addResponsibleData).subscribe({
            next: async () => {
                this.global.closeModalInfo('add-responsavel');
                await this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Um novo responsável por este processo foi designado.',
                });
            },
        });
    }

    private showMissingDispatchAlert(action: 'arquivacao' | 'conclusao'): void {
        this.global.swal.fire({
            title: `Erro ao ${action === 'arquivacao' ? 'arquivar' : 'concluir'} Processo`,
            text: 'Você precisa de um despacho para concluir a ação',
            icon: 'error',
        });
    }

    private confirmProcessAction(actionField: 'arquivacao' | 'conclusao', action: () => void) {
        this.global.swal
            .fire({
                title: `Você deseja ${actionField === 'arquivacao' ? 'arquivar' : 'concluir'} o Processo?`,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    action();
                }
            });
    }

    joinOnProccess() {
        const joinOnProccessData = {
            PRCH_Historico: `O processo está em análise por ${this.global.user().PE_Nome}`,
            PRC_CodigoResponsavel: this.global.user().PE_CodigoPessoa,
        };

        this.processService.join(this.process.PRC_CodigoProcesso, joinOnProccessData).subscribe({
            next: async () => {
                this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Você agora é responsável pelo processo.',
                });
            },
        });
    }

    transmitProcess(type) {
        if (type === 'interna') {
            this.entity.tramitacao.PRCH_Historico = `${this.global.user().PE_Nome
                } tramitou para ${this.mappingPersons()} - Aceite pendente`;
        } else if (type === 'externa') {
            this.entity.tramitacao.PRCH_Historico = `${this.global.user().PE_Nome
                } tramitou para ${this.mappingDepartamento()}`;
        }

        this.entity.tramitacao.PRC_DepartamentoTramitador = this.global.user().PE_CodigoDepartamento;
        this.entity.tramitacao.USR_CodigoUsuario = this.global.user().USR_CodigoUsuario;
        this.processService.transmit(this.process.PRC_CodigoProcesso, this.entity.tramitacao).subscribe({
            next: async () => {
                this.global.closeModalInfo(this.modalName + '-add-departamento');

                if (type == 'externa') {
                    this.global.router.navigate(['/process/transferred']);
                }

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Um novo responsável por este processo foi designado.',
                });
                this.getProcess(this.process.PRC_CodigoProcesso);
                window.location.reload();
            },
        });
    }

    mappingDepartamento() {
        return this.departments.filter((departament) => {
            return departament.DEP_CodigoDepartamento == this.entity.tramitacao.PRC_CodigoDepartamentoAtual;
        })[0].DEP_Descricao;
    }

    public mappingPersons() {
        return this.persons.filter((person) => {
            return person.PE_CodigoPessoa == this.entity.tramitacao.PRC_CodigoResponsavel;
        })[0].PE_Nome;
    }

    public handleNavigationBackButton() {
        if (this.userService.isCidadao()) {
            this.global.router.navigate(['/requests/all']);
        } else {
            window.history.back();
        }
    }

    addComment() {
        if (this.commentText.trim() !== '') {
            this.entity.comentario.COM_Descricao = this.commentText;
            this.entity.comentario.COM_CodigoUsuario = this.global.user().USR_CodigoUsuario;
            this.entity.comentario.COM_CodigoProcesso = this.process.PRC_CodigoProcesso;
            this.entity.comentario.COM_DataComentario = new Date();
            this.entity.comentario.USR_foto = this.global.user().USR_foto;
            this.saveComment();
        }
    }

    private saveComment() {
        this.entity.comentario.COM_Descricao = this.commentText;
        this.commentService.save(this.entity.comentario).subscribe({
            next: (response: any) => {
                this.commentText = '';
                this.comments.unshift({
                    ...response.entity,
                    USR_foto: this.global.user().USR_foto,
                    formattedDate: formatDistanceToNow(new Date(response.entity.COM_DataComentario), {
                        addSuffix: true,
                        locale: ptBR,
                    }),
                });

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Novo comentário adicionado ao processo.',
                });
            },
        });
    }

    initCommentEdition(comentario: any) {
        comentario.editText = comentario.COM_Descricao;
    }

    handleSaveCommentEdition(comentario: any) {
        comentario.COM_Descricao = comentario.editText;

        this.commentService.save(comentario).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O comentário foi alterado.',
                });
                this.global.closeModalInfo(this.modalName + comentario.COM_CodigoComentario);
            },
        });
    }

    getStatusClass(status: string): string {
        switch (status) {
            case '1':
                return 'badge-primary';
            case '2':
                return 'badge-success';
            default:
                return 'badge-default';
        }
    }

    getUploadedFile(event: any) {
        this.showDropzonePreview = true;
        this.entity.documentos[event.file.typeDocument].push(event.file);
    }

    onDeleteFileUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.documentos[event.typeDocument];

        if (documentosArray && documentosArray.length > event.index) {
            documentosArray.splice(event.index, 1);
        }
    }

    handleSaveDispatchReport() {
        this.showDropzonePreview = false;
        this.processService.addDispatchReport(this.process.PRC_CodigoProcesso, this.entity.documentos).subscribe({
            next: () => {
                this.getProcess(this.process.PRC_CodigoProcesso);
                this.entity.documentos.parecerDespacho.length = 0;
                this.showDropzonePreview = false;

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Um novo parecer de despacho foi adicionado ao processo.',
                });
            },
        });
    }

    public getUploadedComplementaryFiles(event: any) {
        this.showDropzonePreview = true;
        this.entity.processAttachments[event.file.typeDocument].push(event.file);
    }

    public onDeleteComplementaryFilesUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.documentos[event.typeDocument];

        if (documentosArray && documentosArray.length > event.index) {
            documentosArray.splice(event.index, 1);
        }
    }

    public handleSaveComplementaryFiles() {
        this.processService
            .addComplementaryFiles(this.process.PRC_CodigoProcesso, this.entity.processAttachments)
            .subscribe({
                next: () => {
                    this.entity.despacho.PDES_Descricao = '';

                    this.messageService.add({
                        severity: 'success',
                        icon: 'ph ph-check-circle',
                        closable: true,
                        summary: 'Sucesso!',
                        detail: 'Novo documento complementar adicionado ao processo',
                    });

                    this.showDropzonePreview = false;
                    this.entity.processAttachments.complementaryFiles.length = 0;
                    this.getProcess(this.process.PRC_CodigoProcesso);
                },
            });
    }

    public getUploadedCertificate(event: any) {
        this.showDropzonePreview = true;
        this.entity.processCertificates[event.file.typeDocument].push(event.file);
    }

    public onDeleteCertificateUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.processCertificates[event.typeDocument];

        if (documentosArray && documentosArray.length > event.index) {
            documentosArray.splice(event.index, 1);
        }
    }

    public handleSaveCertificate() {
        this.processService.addCertificate(this.process.PRC_CodigoProcesso, this.entity.processCertificates).subscribe({
            next: () => {
                this.entity.despacho.PDES_Descricao = '';

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Nova certidão adicionada ao processo',
                });

                this.showDropzonePreview = false;
                this.getProcess(this.process.PRC_CodigoProcesso);
                this.entity.processCertificates.certificates.length = 0;
            },
        });
    }

    public getUploadedFixedAttachment(event: any) {
        this.showDropzonePreview = true;
        this.entity.fixedAttachments.documents.push(event.file);
    }

    public onDeleteFixedAttachmentUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.fixedAttachments.documents;

        if (documentosArray && documentosArray.length >= event.index) {
            documentosArray.splice(event.index - 1, 1);
        }

        console.log('DOCUMENTOS', documentosArray);
    }

    getDocumentoTypes(documentos: any): string[] {
        return Object.keys(documentos);
    }

    handleSaveRequesterCorrection() {
        Swal.fire({
            title: 'Prosseguir com a correção?',
            text: 'Antes de prosseguir, verifique se anexou corretamente os documentos corrigidos conforme as considerações do responsável. ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService
                    .sendRequesterCorrection(this.process.PRC_CodigoProcesso, this.entity.fixedAttachments)
                    .subscribe({
                        next: () => {
                            this.messageService.add({
                                severity: 'success',
                                icon: 'ph ph-check-circle',
                                closable: true,
                                summary: 'Sucesso!',
                                detail: 'Sua correção será avaliada pelo responsável. Se estiver tudo certo, o responsável dará continuidade ao processo',
                                life: 8000
                            });

                            this.showDropzonePreview = false;

                            this.getProcess(this.process.PRC_CodigoProcesso);
                        },
                    });
            } else {
                Swal.close()
            }
        });
    }

    get hasFixedAttachmentsUploaded(): boolean {
        return (
            this.entity.fixedAttachments.procuracao.length > 0 ||
            this.entity.fixedAttachments.requerimento.length > 0 ||
            this.entity.fixedAttachments.complementar.length > 0
        );
    }

    public downloadFile(attachmentName: string, organization: string) {
        this.uploadService.download(attachmentName, organization).subscribe({
            next: (response: any) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            },
            error: (error: any) => {
                console.error('Erro no download:', error);
            },
        });
    }

    public navigateToDirectMessages() {
        this.global.router.navigate([`process/view/${this.encryptedId}/mail/received`]);
    }

    async searchResponsibles() {
        const currentUserCode = this.global.user().PE_CodigoPessoa;

        if (this.userService.isDiretorDepartamento()) {
            await this.userService
                .listAll(
                    `"PE_CodigoDepartamento" = ${this.global.user().PE_CodigoDepartamento
                    } AND "PE_CodigoPessoa" != ${currentUserCode}`,
                    0,
                )
                .toPromise()
                .then((response: any) => {
                    this.responsibles = response.data;
                });
        } else {
            await this.userService
                .listAll(`"USR_UsuarioInterno" = true AND "PE_CodigoPessoa" != ${currentUserCode}`, 0)
                .toPromise()
                .then((response: any) => {
                    this.responsibles = response.data;
                });
        }
    }

    public toggleFields() {
        if (this.tipoTramitacao === 'interna') {
            this.entity.tramitacao.PRC_CodigoDepartamentoAtual = null;
        } else if (this.tipoTramitacao === 'externa') {
            this.getDepartments();
            this.entity.tramitacao.PRC_CodigoResponsavel = null;
        }
    }

    public async handleReturnToRequester() {
        this.sidebarVisible = false;

        const sendToCorrectionData = {
            description: this.reasonReturn,
            history: `O processo foi movido para correção por ${this.global.user().PE_Nome} `,
            attachments: this.entity.documentos.correctionFiles,
            editDescription: this.editDescriptionAllowed
        };

        await this.processService.sendToCorrection(this.process.PRC_CodigoProcesso, sendToCorrectionData).subscribe({
            next: () => {
                this.reasonReturn = '';
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O processo foi enviado para correção.',
                });

                this.getProcess(this.process.PRC_CodigoProcesso);
            },
        });
    }

    public correctNameType(type: string) {
        if (type == 'procuracao') {
            return 'PROCURAÇÃO';
        } else {
            return type;
        }
    }

    public deleteFile(name: string, table: string) {

        Swal.fire({
            title: 'Tem certeza que deseja apagar este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.uploadService.deleteFile(name, table, this.process.OR_Sigla).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O arquivo foi apagado com sucesso.',
                        });
                    }
                })
            } else {
                Swal.close()
            }
        });
    }

    getTiposDocumentos(): string[] {
        return Array.from(new Set(this.process.documentos.map((item) => item.SOLA_Tipo)));
    }

    getDocumentosByTipo(tipo: string): any[] {
        return this.process.documentos.filter((item) => item.SOLA_Tipo === tipo);
    }

    handleUpdateStatusToEvaluation() {
        Swal.fire({
            title: 'Tem certeza que deseja atualizar o processo para Em Análise?',
            text: 'Verifique se todos os documentos necessários estão corretos antes de realizar esta ação.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService.updateStatusToEvaluation(this.process.PRC_CodigoProcesso).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O processo foi atualizado para Em Andamento',
                        });
                    }
                })
            } else {
                Swal.close()
            }
        });
    }

    filterDocumentByType(type: string) {
        const list = this.process.documentos.filter((document) => document.SOLA_Tipo === type);
        return list;
    }

    isNewDocumentType(type: string, index: number): boolean {
        if (!this.alreadyFilteredList[type]) {
            this.alreadyFilteredList[type] = index;
            return true;
        }
        return this.alreadyFilteredList[type] === index;
    }

    enableEditing() {
        this.isEditingDescription = true;
    }

    saveDescription() {
        const editDescriptionRequest: IChangeRequestDescriptionSchema = {
            requestId: Number(this.process.PRC_CodigoSolicitacao),
            description: this.process.SOL_Descricao
        }

        this.requestService.updateProcessDescription(editDescriptionRequest).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Descrição atualizada com sucesso'
                });

                this.isEditingDescription = false;
            },
            error: (err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Falha ao atualizar a descrição'
                });
                console.error(err);
            }
        });
    }

    handleRequesterConsiderations() {
        const data: ISendRequesterConsiderationsSchema = {
            considerations: this.requesterConsiderations,
            correctionId: this.process.PCOR_CodigoCorrecao
        }
        this.processService.saveRequesterConsiderations(this.process.PRC_CodigoProcesso, data).subscribe({
            next: (response: any) => {
                this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Considerações adicionadas com sucesso!'
                });
            }
        })
    }

    /* 
        Poderá corrigir os documentos caso:
        1 - For o solicitante
        2 - For criado pela triagem (Então qualquer um da triagem do órgão poderá fazer o upload dos novos documentos)
        3 - O processo esteja `Em Correção`
        4 - Ainda não for corrigido (PCOR_Status === 0)
    */
    public get canFixDocuments(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM')) &&
            ProcessStatusEnum.IN_CORRECTION === this.process.Status &&
            this.process.PCOR_Status === 0
    }

    /* 
        Poderá adicionar considerações caso:
        1 - For o solicitante
        2 - For criado pela triagem (Então qualquer um da triagem do órgão poderá adicionar considerações)
        3 - O processo esteja `Em Correção`
        4 - Não exista um retorno do cidadão para a correção atual
    */
    public get canAddConsiderations(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM')) &&
            (this.process.Status === ProcessStatusEnum.IN_CORRECTION || this.process.Status === ProcessStatusEnum.ANSWERED) &&
            !this.process.PCOR_RetornoCidadao
    }

    /* 
        Poderá visualizar as informações de correção caso:
        1 - For o solicitante
        2 - O usuário logado seja do mesmo órgão em que o processo está vinculado
        3 - O processo esteja `Em Correção`;
    */
    public get canViewCorrectionDetails(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            this.global.user().USR_CodigoOrgao === this.process.SOL_CodigoOrgao) &&
            (this.process.Status === ProcessStatusEnum.IN_CORRECTION || this.process.Status === ProcessStatusEnum.ANSWERED)
    }

    /* 
        Poderá fazer upload dos documentos complementares caso:
        1 - For responsável pelo processo
        2 - For o solicitante
        3 - For criado pela triagem (Então qualquer um da triagem do órgão poderá fazer o upload)
        4 - É obrigatório está nos status de `Em Andamento` ou `Em Correção`;
    */
    public get canUploadProcessAttachments(): boolean {
        const allowedStatus = [ProcessStatusEnum.IN_PROGRESS, ProcessStatusEnum.IN_CORRECTION];

        return (this.process.PRC_CodigoResponsavel == this.global.user().PE_CodigoPessoa) ||
            (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante) ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM') &&
            allowedStatus.includes(this.process.Status)
    }

    /* 
        Poderá visualizar e preencher os dados de anulação do processo caso:
        1 - Quem solicitou for do grupo `MEMBRO DE DEPARTAMENTO` e o usuário logado for do grupo `DIRETOR DE DEPARTAMENTO` 
        2 - O processo não estiver com o status `ANULADO`.
        3 - Ainda não foi avaliado.
    */

    public get isCancellationRequestSectionVisible(): boolean {
        return ((this.process.cancellation_requester_group === UsersGroupEnum.DEPARTMENT_MEMBER &&
            this.global.user().USG_CodigoGrupo === UsersGroupEnum.DEPARTMENT_DIRECTOR) ||
            this.global.user().USR_CodigoUsuario === this.process.PSA_Solicitante) &&
            this.process.Status !== ProcessStatusEnum.CANCELED &&
            !this.process.PSA_AvaliadaPor
    }

    /* 
        Poderá visualizar o botão de enviar solicitação de anulação caso:
        1 - O processo esteja nos status: `NOVO`, `EM ANDAMENTO`, `EM CORREÇÃO` ou `VENCIDO`;
        2 - Não tenha nenhuma solicitação de anulação pendente.
        3 - O responsável pelo processo seja o usuário logado.
        4 - O usuário logado seja um `MEMBRO DE DEPARTAMENTO`
    */
    public get isRequestCancellationButtonVisible(): boolean {
        return ((this.process.Status === ProcessStatusEnum.NEW ||
            this.process.Status === ProcessStatusEnum.IN_PROGRESS ||
            this.process.Status === ProcessStatusEnum.IN_CORRECTION ||
            this.process.Status === ProcessStatusEnum.OUTDATED) &&
            !this.process.PSA_DataSolicitacao) &&
            this.process.PRC_CodigoResponsavel === this.global.user().PE_CodigoPessoa &&
            this.userService.isMembroDepartamento()
    }

    /* 
        Poderá visualizar o botão de anular processo caso:
        1 - O processo esteja nos status: `NOVO`, `EM ANDAMENTO`, `EM CORREÇÃO` ou `VENCIDO`;
        2 - Não tenha nenhuma solicitação de anulação pendente.
        3 - O responsável pelo processo seja o usuário logado.
        4 - O usuário logado seja um `DIRETOR DE DEPARTAMENTO`
    */
    public get isCancellationButtonVisible(): boolean {
        return ((this.process.Status === ProcessStatusEnum.NEW ||
            this.process.Status === ProcessStatusEnum.IN_PROGRESS ||
            this.process.Status === ProcessStatusEnum.IN_CORRECTION ||
            this.process.Status === ProcessStatusEnum.OUTDATED) &&
            !this.process.PSA_DataSolicitacao) &&
            this.process.PRC_CodigoResponsavel === this.global.user().PE_CodigoPessoa &&
            this.userService.isDiretorDepartamento()
    }

    handleAddRequestToCancelProcess() {
        const cancelProcessData: IRequestToCancelProcessSchema = {
            reasonCancel: this.entity.cancelProcess.reasonCancel,
            userId: this.global.user().USR_CodigoUsuario,
            history: `${this.global.user().PE_Nome} enviou uma solicitação de anulação do processo.`
        }

        this.processService.addRequestToCancel(this.process.PRC_CodigoProcesso, cancelProcessData).subscribe({
            next: () => {
                this.getProcess(this.process.PRC_CodigoProcesso)

                this.isCancelProcessDialogOpen = false;

                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Uma solicitação de anulação foi enviada para o diretor de seu departamento'
                });
            }
        })
    }

    handleCancelProcess() {
        const confirmProcessCancellationData: ICancellationEvaluationSchema = {
            userId: this.global.user().USR_CodigoUsuario,
            history: `${this.global.user().PE_Nome} confirmou a anulação do processo.`,
            evaluationDescription: this.entity.cancelProcess.reasonCancel
        }

        this.isCancelProcessDialogOpen = false;

        Swal.fire({
            title: 'Confirmar anulação do processo?',
            text: 'Após realizar esta ação, a anulação do processo não poderá ser desfeita.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService.confirmCancellationProcess(this.process.PRC_CodigoProcesso, confirmProcessCancellationData).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso)
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'O processo foi anulado'
                        });
                    }
                })
            } else {
                this.isCancelProcessDialogOpen = true;
                Swal.close()
            }
        });
    }

    onProcessCancelled(processId: number) {
        this.getProcess(processId);
    }

    /* 
        O Arquivamento de processo pode ser feito se o processo:
        1 - Estiver Concluído
        2 - Estiver Vencido
        3 - Estiver Anulado
    */
    public get canArchive(): boolean {
        return (this.process.Status === ProcessStatusEnum.CONCLUDED ||
            this.process.Status === ProcessStatusEnum.OUTDATED ||
            this.process.Status === ProcessStatusEnum.CANCELED)
    }

    public showModalArchiveNote() {
        if (!this._haveDispatch && this.process.Status !== ProcessStatusEnum.OUTDATED && this.process.Status !== ProcessStatusEnum.CANCELED) {
            this.showMissingDispatchAlert('arquivacao');
        } else {
            this.isArchiveNoteModalOpen = true
        }
    }

    public get isArchivingInfoVisibile(): boolean {
        return (this.process.Status === ProcessStatusEnum.ARCHIVED &&
            !!this.process.PRC_NotaArquivamento
        )
    }

    getResponsibleRequests(processId: number) {
        this.processService.getResponsibleRequests(processId).subscribe({
            next: (response: any) => {
                this.responsibleRequest = response.data;
            }
        })
    }

    public getCorrections(processId: number) {
        this.processService.getAllCorrections(processId).subscribe({
            next: (response: any) => {
                this.corrections = response.data;
            }
        })
    }

    acceptRejectBeingResponsible(type) {
        this.responsibleRequest.type = type
        let message: any;

        if (type == 'accept') {
            message = {
                severity: 'success',
                icon: 'ph ph-check-circle',
                closable: true,
                summary: 'Sucesso!',
                detail: 'Você aceitou ser responsável desse processo.'
            };
        }
        else {
            message = {
                severity: 'error',
                closable: true,
                detail: 'Você recusou ser responsável desse processo.'
            };
        }

        this.processService.sendAcceptBeingResponsible(this.responsibleRequest.RESAC_CodigoProcesso, this.responsibleRequest).subscribe({
            next: (response: any) => {
                this.getProcess(this.responsibleRequest.RESAC_CodigoProcesso);
                this.messageService.add(message);
                this.global.closeModalInfo('reject-responsible');
                window.location.reload();
            }
        })
    }

    public async handleCancelComplementaryFiles(fileId: number) {

        const result = await Swal.fire({
            title: 'Tem certeza que deseja anular este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        });

        if (result.isConfirmed) {
            this.processService.cancelComplementaryFiles(this.process.PRC_CodigoProcesso, fileId).subscribe({
                next: (response: any) => {
                    Swal.fire('Arquivo Anulado!', 'Seu arquivo foi anulado com sucesso.', 'success');

                    this.getProcess(this.process.PRC_CodigoProcesso);
                    window.location.reload();
                },
                error: (error: any) => {
                    console.error('Erro ao anular o arquivo:', error);
                    Swal.fire('Erro ao anular o arquivo', 'Ocorreu um erro ao anular o arquivo.', 'error');
                }
            });
        } else {
            Swal.fire('Operação Cancelada', 'O arquivo não foi anulado.', 'info');
        }
    } catch(error) {
        console.error('Erro ao mostrar confirmação:', error);
    }
}