import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { typeFormRequestEnum } from 'src/app/Enums/TypeFormRequestEnum';
import { subjectRequestFormEnum } from 'src/app/Enums/subjectRequestFormsEnum';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { EvaluationTermService } from 'src/app/app-core/services/evaluation-term.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { SubjectAttachmentService } from 'src/app/app-core/services/subject-attachment.service';
import { SubjectService } from 'src/app/app-core/services/subject.service';
import { TagsService } from 'src/app/app-core/services/tags.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { IListScopeOrganizationResponse } from 'src/app/interfaces/listScope/listScopeOrganizationResponse';
import { IListScopeSubjectResponse } from 'src/app/interfaces/listScope/listScopeSubjectResponse';
import { GenericValidator } from 'src/app/app-core/modules/forms/utilities/genericValidator';
import { subjectsTypeEnum } from 'src/app/Enums/subjectsEnum';

@Component({
    selector: 'app-user-data-request',
    templateUrl: './user-data-request.component.html',
    providers: [OrganizationService, EvaluationTermService, UserService, SubjectService, TagsService]
})
export class UserDataRequestComponent implements OnInit {

    public user;
    public form: FormGroup;
    public isDisabled = true;
    public entity;
    public requiredFields;
    public triagemTermo;
    public modalName = 'solicitacao-sucesso';
    public subjects = [];
    public attachments = [];
    public mimeTypes: string[] = ['application/pdf'];
    public organizations = [];
    public orgao: any;
    public subjectName: string;
    public subjectFormLink: string;
    public subjectGuidelinesLink: string;
    public typeForm: number;
    public orgaoOnlySubject = [];
    public procuracao = [];
    public cpfCnpjMask: string;
    public isOrgaoDisabled: boolean = false;
    public telefoneMask: string;
    public showTipoRepresentante: boolean = false;

    private termScreeningAccepted: boolean = false;

    constructor(
        public global: GlobalService,
        public userService: UserService,
        public evaluationTermService: EvaluationTermService,
        public organizationService: OrganizationService,
        public subjectService: SubjectService,
        public subjectAttachmentService: SubjectAttachmentService,
        public tagsService: TagsService,
        private fb: FormBuilder
    ) {
        this.user = this.global.user();
        this.initForm();
        this.listOrgaos();
    }

    ngOnInit(): void {
        this.requerenteFormGroup.get('SOLR_TipoInscricao')?.valueChanges.subscribe(value => {
            this.updateMask();
        });

        if (this.userService.isTriagem()) {
            this.isDisabled = true;
            this.toggleActiveFields();
        }
    }

    public get requerenteFormGroup() {
        return this.form.get('requerente');
    }

    public get solicitacaoFormGroup() {
        return this.form.get('solicitacao');
    }

    private initForm() {
        const codigoOrgao = this.userService.isTriagem() ? this.user.USR_CodigoOrgao : null;
        this.isOrgaoDisabled = this.userService.isTriagem();
        this.form = this.fb.group({
            subject: [null],
            applicantRequest: [false],
            solicitacao: this.fb.group({
                SOL_CodigoOrgao: [codigoOrgao, Validators.required],
                SOL_CodigoAssunto: [null, Validators.required],
                SOL_CodigoSolicitante: [this.user.USR_CodigoPessoa, Validators.required],
                SOL_Status: 0,
                SOLR_TipoInscricao: [''],
                SOL_CodigoDocumento: null,
                SOLH_Historico: ['A solicitação está em análise'],
            }),
            requerente: this.fb.group({
                SOLR_TipoInscricao: ['2'],
                SOLR_CpfCnpj: [this.user.PE_CPF],
                SOLR_NomeRazaoSocial: [this.user.PE_Nome],
                SOLR_Email: [this.user.PE_Email],
                SOLR_Telefone: [null],
                SOLR_TipoRepresentante: [null]
            })
        });

        if (this.userService.isTriagem() && codigoOrgao) {
            this.form.get('solicitacao.SOL_CodigoOrgao').disable();
            this.listAssuntoByOrgao(codigoOrgao);
        }
    }

    public updateMask() {
        const tipoInscricao = this.requerenteFormGroup.get('SOLR_TipoInscricao')?.value;
        this.cpfCnpjMask = tipoInscricao == 1 ? '00.000.000/0000-00' : '000.000.000-00';
        this.showTipoRepresentante = tipoInscricao === '1';
        if (tipoInscricao == 2) {
            this.requerenteFormGroup.get('SOLR_TipoRepresentante')?.setValue(null, { emitEvent: false });
            this.requerenteFormGroup.get('SOLR_TipoRepresentante')?.setValidators(null);
            this.requerenteFormGroup.get('SOLR_TipoRepresentante')?.updateValueAndValidity();
        }
        else {
            this.requerenteFormGroup.get('SOLR_TipoRepresentante')?.setValidators(Validators.required);
            this.requerenteFormGroup.get('SOLR_TipoRepresentante')?.updateValueAndValidity();
        }
    }

    public toggleActiveFields() {
        const requerenteFields = [
            'requerente.SOLR_CpfCnpj',
            'requerente.SOLR_NomeRazaoSocial',
            'requerente.SOLR_Email',
            'requerente.SOLR_Telefone',
        ];

        if (this.isDisabled) {
            this.form.get('applicantRequest').patchValue(true);
            this.setRequerenteFields([
                {
                    name: "SOLR_TipoInscricao",
                    value: null,
                    addvalidators: [Validators.required]
                },
                {
                    name: "SOLR_CpfCnpj",
                    value: null,
                    addvalidators: [Validators.required, GenericValidator.isValidCpfOrCnpj()]
                },
                {
                    name: "SOLR_NomeRazaoSocial",
                    value: null,
                    addvalidators: [Validators.required]
                },
                {
                    name: "SOLR_Email",
                    value: null,
                    addvalidators: [GenericValidator.emailValidator()]
                },
                {
                    name: "SOLR_Telefone",
                    value: null,
                    addvalidators: this.userService.isTriagem() ? [] : [Validators.required]
                }
            ]);
            this.toggleFields(false, requerenteFields);

            this.form.get('requerente.SOLR_TipoInscricao').valueChanges.subscribe(value => {
                this.toggleFields(!!value, requerenteFields);
            });

            if (this.global.user().USR_Administrador || this.userService.isTriagem()) {
                this.checkIsAcceptedTriagemTermo();
            }
        }
        else {
            this.form.get('applicantRequest').patchValue(false);
            this.setRequerenteFields([
                {
                    name: "SOLR_TipoInscricao",
                    value: "2",
                    removeValidators: [Validators.required]
                },
                {
                    name: "SOLR_CpfCnpj",
                    value: this.user.PE_CPF,
                    removeValidators: [Validators.required]
                },
                {
                    name: "SOLR_NomeRazaoSocial",
                    value: this.user.PE_Nome,
                    removeValidators: [Validators.required]
                },
                {
                    name: "SOLR_Email",
                    value: this.user.PE_Email,
                    removeValidators: [Validators.required]
                }
            ]);
            this.toggleFields(false, requerenteFields);
        }

        this.isDisabled = !this.isDisabled;
    }

    public getUploadedFile(event) {
        this.procuracao.push(event.file);
    }

    public onDeleteFileUploaded(event) {
        this.procuracao.splice(event.index, 1)
    }

    public checkIsAcceptedTriagemTermo() {
        const userId = parseInt(this.global.user().USR_CodigoUsuario);

        this.evaluationTermService.listAll(`"TT_CodigoUsuario" = ${userId}`).subscribe({
            next: (response: any) => {
                this.triagemTermo = response.data;

                if (this.triagemTermo.length == 0) {
                    this.global.showModalInfo(this.modalName + '-termo');
                } else {
                    this.termScreeningAccepted = true;
                }
            },
        });
    }

    public setOrgaoNameAndForm(id: number) {
        this.organizationService.getEntity(id).subscribe({
            next: (response: any) => {
                this.orgao = response.entity.orgao;
                if (this.isSeplag) {
                    this.typeForm = typeFormRequestEnum.SEPLAG;
                }
                if (this.isSemurb) {
                    this.typeForm = typeFormRequestEnum.SEMURB;
                }
            },
        });
    }

    public listAssuntoByOrgao(id: number) {
        this.subjectService.listAll(`"AS_CodigoOrgao" = ${id} AND ("AS_TipoProcesso" = ${subjectsTypeEnum.EXTERNO} OR "AS_TipoProcesso" = ${subjectsTypeEnum.INTERNO_EXTERNO})`, 0).subscribe({
            next: (response: IListScopeSubjectResponse) => {
                this.subjects = response.data
            },
        });

        this.setOrgaoNameAndForm(id)
    }

    public setSubjectNameAndAttachments() {
        this.global.startLoading();
        this.subjectService.getEntity(this.solicitacaoFormGroup.get('SOL_CodigoAssunto').value).subscribe({
            next: (response: any) => {
                this.attachments = response.entity.attachments;
                this.subjectName = response.entity.AS_Descricao;
                this.subjectFormLink = response.entity.AS_LinkFormulario;
                this.subjectGuidelinesLink = response.entity.AS_LinkDiretrizes;

                if (!this.isSeplag && !this.isSemurb) {
                    if (response.entity.AS_CodigoTag) {
                        this.typeFormRequest(response.entity.AS_CodigoTag);
                    }
                    else if (this.subjectName == subjectRequestFormEnum.INSCRICAO_MUNICIPAL) {
                        this.typeForm = typeFormRequestEnum.INSCRICAO;
                    }
                    else {
                        this.typeForm = null;
                    }
                }
            },
            complete: () => {
                this.global.stopLoading();
            }
        });
    }

    private listOrgaos() {
        this.organizationService.listAll().subscribe({
            next: (response: IListScopeOrganizationResponse) => {
                this.organizations = response.data.filter(org =>
                    org.OR_TipoProcessoAceito === 1 || org.OR_TipoProcessoAceito === 3
                );
            },
        });
    }

    private setRequerenteFields(fields: { name: string, value: any, addvalidators?: any[], removeValidators?: any[] }[]) {
        for (const field of fields) {
            this.requerenteFormGroup.get(field.name).setValue(field.value);
            this.requerenteFormGroup.get(field.name).setValidators(field.addvalidators);
            this.requerenteFormGroup.get(field.name).removeValidators(field.removeValidators);
        }
    }

    public typeFormRequest(codigoTag: number) {
        this.tagsService.getEntity(codigoTag).subscribe({
            next: (response: any) => {
                switch (response.entity.TAG_Nome) {
                    case 'IPTU':
                        this.typeForm = typeFormRequestEnum.IPTU;
                        break;
                    case 'ITBI':
                        this.typeForm = typeFormRequestEnum.ITBI;
                        break;
                }
            }
        })
    }

    private get isSeplag() {
        return this.orgao.OR_Descricao.includes("SECRETARIA DE PLANEJAMENTO, ADMINISTRAÇÃO E GESTÃO");
    }

    private get isSemurb() {
        return this.orgao.OR_Descricao.includes("SECRETARIA DE MEIO AMBIENTE E URBANISMO");
    }

    private toggleFields(enable: boolean, fields: string[]) {
        fields.forEach(field => {
            const control = this.form.get(field);
            if (control) {
                if (enable) {
                    control.enable();
                }
                else {
                    control.disable();
                }
            }
        });
    }
}
