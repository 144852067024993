import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class AtendimentoService extends GerinusService {
    constructor(
        public http: HttpClient,
    ) {
        super(http);
        this.entityName = 'atendimento';
        this.idField = 'ATD_Id';
        this.model = 'AtendimentoModel';
    }

}
