export enum AutoridadeEnum {
    SECRETARIO_INFRAESTRUTURA = "secretario_infra",
    SECRETARIO_EDUCACAO = "secretario_educacao",
    SECRETARIO_REGEGIONAL_PECEM = "secretario_reg_pecem",
    PREFEITO_MUNICIPAL = "prefeito_municipal",
    OUVIDOR = "ouvidor",
    SECRETARIO_SAUDE = "secretario_saude",
    CONTROLADOR = "controlador",
    SECRETARIO_ADMINISTRACAO = "secretario_administracao",
    SECRETARIO_GOVERNO = "secretario_governo",
    SECRETARIO_TRABALHO_SOCIAL = "secretario_trab_social",
    PROCURADOR_GERAL = "procurador_geral",
    SECRETARIO_GERAL = "secretario_geral",
    SECRETARIO_FINANCAS = "secretario_financas",
    COORDENADOR_FMPS = "coordenador_fmps",
    OUTROS = "outros"
}