import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/app/app-core/services/login.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-gov-br',
    templateUrl: './login-gov-br.component.html',
    styleUrls: ['./login-gov-br.component.css'],
})
export class LoginGovBrComponent implements OnInit {
    public loginGovBr: string;
    public environment = environment;
    public login = 'admin';
    public logins = [
        {
            name: 'Administrador',
            value: 'admin',
        },
        {
            name: 'Triagem',
            value: 'triagem',
        },
        {
            name: 'Cidadão',
            value: 'cidadao',
        },
        {
            name: 'Diretor de Departamento',
            value: 'diretor_departamento',
        },
        {
            name: 'Membro de Departamento',
            value: 'membro_departamento',
        },
        {
            name: 'Adm. do Órgão',
            value: 'adm_orgao',
        },
        {
            name: 'Secretário',
            value: 'secretario',
        }
    ];

    constructor(
        public global: GlobalService,
        public router: Router,
        public service: LoginService,
    ) {
        this.configureGovBR();
    }

    ngOnInit(): void { }

    public async configureGovBR() {
        const returnURL = encodeURIComponent(environment.govBR.returnURL);
        const nonce = this.global.md5(`${environment.govBR.secret}/${Date.now()}`);
        const state = nonce;
        const codeChallenge = await this.global.generateCodeChallenge(environment.govBR.secret);
        this.loginGovBr = `${environment.govBR.url}/authorize?response_type=code&client_id=${environment.govBR.clientID}&scope=openid+email+profile&redirect_uri=${returnURL}&nonce=${nonce}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    }

    public gotoGovBr() {
        if (this.global.govBr) {
            window.location.href = this.loginGovBr;
        }
        else {
            this.service.authentication(false, null, this.login, this.login);
        }
    }
}
