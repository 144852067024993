import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewRequestComponent } from '../../../pages/new-request/new-request.component';
import { RequestsComponent } from '../../../pages/requests/requests.component';
import { ViewCorrectionRequestsComponent } from '../../../pages/view-correction-requests/view-correction-requests.component';
import { PageNotFoundComponent } from 'src/app/app-core/shared/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Solicitações',
        },
        children: [
            {
                path: '',
                redirectTo: 'ongoing',
                pathMatch: 'full'
            },
            {
                path: 'new',
                component: NewRequestComponent,
                data: {
                    title: 'Cadastro de Solicitações',
                },
            },
            {
                path: 'evaluation',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações em análise',
                },
            },
            {
                path: 'correction',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações Aguardando Correção',
                },
            },
            {
                path: 'ongoing',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações em análise',
                },
            },
            {
                path: 'processes',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações aceitas',
                },
            },
            {
                path: 'canceled',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações anuladas',
                },
            },
            {
                path: 'expired',
                component: RequestsComponent,
                data: {
                    title: 'Solicitações Vencidas',
                },
            },
            {
                path: 'view/:id',
                component: ViewCorrectionRequestsComponent,
                data: {
                    title: 'Correção e visualização de solicitações',
                },
            },
            {
                path: 'edit/:id',
                component: ViewCorrectionRequestsComponent,
                data: {
                    title: 'Correção e visualização de solicitações',
                },
            },
            {
                path: '**', 
                component: PageNotFoundComponent,
                pathMatch: 'full'
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RequestsRoutingModule {}
