import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class IbgeService {

    private url = "https://servicodados.ibge.gov.br/api/v1/";

    constructor(private httpClient: HttpClient) { }

    public get ufs() {
        return this.httpClient.get(this.url + "localidades/estados?orderBy=nome");
    }

    public municipiosByUf(uf: string) {
        return this.httpClient.get(this.url + "localidades/estados/" + uf + "/municipios")
    }
}