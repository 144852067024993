import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GerinusService } from '../lib/gerinus.service';
import { GlobalService } from '../lib/global.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService extends GerinusService {
    constructor(
        public global: GlobalService,
        public http: HttpClient,
        public router: Router,
    ) {
        super(http);
    }

    public authentication(isGovBr, code?, username?, password?) {
        if (isGovBr) {
            let auth = btoa(environment.govBR.clientID + ':' + environment.govBR.secret);
            let headers = { Authorization: 'Basic ' + auth };
            let url = `${environment.govBR.url}/token`;

            this.http
                .post(url, null, {
                    headers,
                    params: {
                        grant_type: 'authorization_code',
                        code,
                        redirect_uri: environment.govBR.returnURL,
                        code_verifier: environment.govBR.secret,
                    },
                })
                .subscribe({
                    next: (response: any) => {
                        let data = this.decodeJWT(response.id_token);
                        this.http
                            .post(this.apiURL() + '/api/login', data, this.headers())
                            .subscribe((response: any) => {
                                if (response.ok) {
                                    let user = response.user;
                                    console.log('[Subscribe LoginService] user response: ', response);
                                    localStorage.setItem('user', JSON.stringify(user));
                                    this.router.navigate(['/inicio'], { replaceUrl: true });
                                } else {
                                    this.global.stopLoading();
                                    this.global.swal.fire({
                                        title: 'Erro!',
                                        text: response.message,
                                        icon: response.message_type,
                                    });
                                }
                            });
                    },
                });
        } else {
            let data = {
                username: username,
                password: password,
            };
            this.global.startLoading('Validando Usuário...');
            this.http.post(this.apiURL() + '/api/login', data, this.headers()).subscribe((response: any) => {
                if (response.ok) {
                    let user = response.user;
                    console.log('[Subscribe LoginService] user response: ', response);
                    localStorage.setItem('user', JSON.stringify(user));
                    this.router.navigate(['/inicio'], { replaceUrl: true });
                } else {
                    this.global.stopLoading();
                    this.global.swal.fire({
                        title: 'Erro!',
                        text: response.message,
                        icon: response.message_type,
                    });
                }
            });
        }
    }

    public logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('modal-change-group-displayed');
        this.router.navigate(['/principal']);
    }

    public decodeJWT(token) {
        const parts = token.split('.');

        if (parts.length !== 3) {
            console.error('Token JWT inválido');
            return null;
        }

        const payload = this.base64UrlDecode(parts[1]);

        try {
            return JSON.parse(payload);
        } catch (error) {
            console.error('Erro ao analisar o payload do token:', error.message);
            return null;
        }
    }

    public base64UrlDecode(base64Url) {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return atob(base64);
    }
}
