import { Component, OnInit, Input } from '@angular/core';
import $ from 'jquery';
import { LoginService } from 'src/app/app-core/services/login.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    providers: [LoginService],
})
export class MenuItemComponent implements OnInit {
    @Input() menu;

    ngOnInit(): void {
        $('.children-item').click(function () {
            $(this).find('.caret').toggleClass('rotate-180');
        });
    }

    constructor(public loginService: LoginService) {}
}
