import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-view-free-process',
    templateUrl: './view-public-process.component.html',
    styleUrls: ['./view-public-process.component.css'],
})
export class ViewPublicProcessComponent implements OnInit {
    @Input() processo;

    constructor() { }

    ngOnInit(): void {
    }

    maskCpf(cpf: string): string {
        if (!cpf || cpf.length !== 11) {
            return cpf;
        }

        const formattedCpf = `${cpf.substring(0, 2)}*.***.***-${cpf.substring(cpf.length - 2)}`;
        return formattedCpf;
    }

    maskEmail(email: string): string {
        if (!email || email.indexOf('@') === -1) {
            return email;
        }

        const [nomeUsuario, dominio] = email.split('@');
        const maskedUsername = nomeUsuario.substring(0, 3) + '*'.repeat(nomeUsuario.length - 3);
        const maskedEmail = `${maskedUsername}@${dominio}`;
        return maskedEmail;
    }



}
