import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCoreModule } from '../../app-core.module';
import { ProcessRoutingModule } from './proccess-routing.module';
import { GerinusToolsModule } from '../../lib/gerinus-tools.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';

import { ViewProcessComponent } from '../../../pages/view-processo/view-process.component';
import { ProcessComponent } from '../../../pages/process/process.component';
import { ViewProcessComponentsModule } from 'src/app/pages/view-processo/components/view-process-components.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'ng-block-ui';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    imports: [
        CommonModule,
        GerinusToolsModule,
        AppCoreModule,
        ComponentsModule,
        ProcessRoutingModule,
        NgbModule,
        ViewProcessComponentsModule,
        AutoCompleteModule,
        SidebarModule,
        ToastModule,
        DropdownModule,
        BlockUIModule,
        DialogModule,
        CalendarModule,
        MultiSelectModule,
        CheckboxModule,
        DropdownModule,
    ],
    declarations: [ViewProcessComponent, ProcessComponent],
    exports: [GerinusToolsModule, ViewProcessComponentsModule],
})
export class ProcessModule { }
