export enum iptuFormEnum {
    IPTU_ISENÇÃO_VIÚVAS = 'IPTU - ISENÇÃO VIÚVAS',
    IPTU_ISENÇÃO_SEM_FINS_LUCRATIVOS = 'IPTU - ISENÇÃO SEM FINS LUCRATIVOS',
    IPTU_ISENÇÃO_CEDIDO_GRATUITAMENTE = 'IPTU - ISENÇÃO CEDIDO GRATUITAMENTE',
    IPTU_ISENÇÃO_SERVIDOR_PÚBLICO = 'IPTU - ISENÇÃO SERVIDOR PÚBLICO',
    IPTU_ISENÇÃO_LOCADO_NO_MUNICÍPIO = 'IPTU - ISENÇÃO LOCADO NO MUNICÍPIO',
    IPTU_ISENÇÃO_IPTU_DOENÇAS_GRAVES = 'IPTU - ISENÇÃO DE DOENÇAS GRAVES',
    IPTU_REQUERIMENTO_IMUNIDADE_DE_IPTU = 'IPTU - REQUERIMENTO DE IMUNIDADE IPTU',
    IPTU_REQUERIMENTO_DE_IPTU = 'IPTU - REQUERIMENTO DE IPTU'
}

export enum itbiFormEnum {
    ITBI_REQUERIMENTO = 'ITBI - REQUERIMENTO DE ITBI',
    ITBI_SOLICITACAO_NÃO_INCIDENCIA = 'ITBI - SOLICITAÇÃO DE NÃO INCIDÊNCIA',
    ITBI_SOLICITAÇÃO_DE_ISENÇÃO_DE_ITBI = 'ITBI - SOLICITAÇÃO DE ISENÇÃO DE ITBI'
}

export enum seplagFormEnum {
    ITBI_REQUERIMENTO = 'ITBI - Requerimento de ITBI'
}