import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
    constructor() {}

    public emailValidator(email: string) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }
}
