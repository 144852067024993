import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class ProccessCommentService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'processo_comentario';
        this.idField = 'COM_CodigoComentario';
        this.model = 'ProcessoComentarioModel';
    }
}
