import { Component, Input, OnInit, ViewChild } from '@angular/core';
import FormRequest from '../common/formRequest';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { EnderecoFormComponent } from 'src/app/components/endereco-form/endereco-form.component';
import { CepService } from 'src/app/app-core/lib/cep.service';
import { IbgeService } from 'src/app/app-core/lib/ibge.service';

@Component({
	selector: 'app-form-request-inscricao-municipal',
	templateUrl: './form-request-inscricao-municipal.component.html',
	providers: [FiscoService, IbgeService, CepService]
})
export class FormRequestInscricaoMunicipalComponent extends FormRequest implements OnInit {

	@Input() applicantDetails;

	@ViewChild('enderecoFormApplicant') enderecoFormApplicant: EnderecoFormComponent;

	public listRequestDescription = [
		'CADASTRO DE INSCRIÇÃO MUNICIPAL',
		'REATIVAÇÃO DA INSC. DE ISS',
		'ALTERAÇÃO DE DADOS CADASTRAIS DO ISS (INFORMAR NO CAMPO DE DESCRIÇÃO O TIPO DE ALTERAÇÃO)',
		'BAIXA DE INSCRIÇÃO DE ISS'
	];
	public listRequestSpecies = [
		'Autônomo',
		'Equiparada a empresa'
	]
	public listTaxationTypes = [
		'Receita',
		'Estimativa',
		'Profissional',
		'Primário',
		'Médio',
		'Superior'
	]

	public estados: [];
	public municipiosPorEstado: [];

	public filteredTaxationTypes: string[] = [];
	public SOL_Descricao: string;
	public activeInscricao = false;

	public inscricaoMunicipalField = "SIM_Inscricao";
	public listFieldsInscricaoMunicipal = [
		"SIM_RequerenteCpfCnpj",
		"SIM_RequerenteRazaoSocial",
		"SIM_RequerenteEmail",
		"SIM_RequerenteTelefone",
		"SIM_RequerenteCEP",
		"SIM_RequerenteUF",
		"SIM_RequerenteMunicipio",
		"SIM_RequerenteLogradouro",
		"SIM_RequerenteNumero",
		"SIM_RequerenteComplemento",
		"SIM_RequerenteBairro",
		"SIM_AtividadeMunicipio",
		"SIA_CodigoAtividade",
		"SIM_AtividadeRfb",
		"SIM_CRC",
		"SIM_ResponsavelRazaoSocial",
		"SIM_ResponsavelCpfCnpj"
	]

	public inscricaoField = 'SIM_InscricaoIptu';
	public listFieldsInscricao = [
		"SIM_AreaUtilizada",
		"SIM_CEP",
		"SIM_Logradouro",
		"SIM_Numero",
		"SIM_Bairro"
	];

	constructor(
		public fb: FormBuilder,
		public fiscoService: FiscoService,
		public ibgeService: IbgeService,
		public cepService: CepService
	) {
		super(fb, fiscoService);
	}

	ngOnInit(): void {
		this.initForm();
	}

	public initForm() {
		this.form = this.fb.group({
			SIM_Descricao: [null, [Validators.required]],
			SIM_RequerenteCpfCnpj: [null, [Validators.required]],
			SIM_AtividadeRfb: [null, [Validators.required]],
			SIM_AtividadeMunicipio: [null, [Validators.required]],
			SIM_RequerenteRazaoSocial: [null, [Validators.required]],
			SIM_RequerenteEmail: [null, [Validators.required]],
			SIM_RequerenteTelefone: [null, [Validators.required]],
			SIM_EspecieRequerente: [null, [Validators.required]],
			SIM_ResponsavelRazaoSocial: [null, [Validators.required]],
			SIM_ResponsavelCpfCnpj: [null, [Validators.required]],
			SIM_CRC: [null, [Validators.required]],
			SIM_ResponsavelLegalNome: [null, [Validators.required]],
			SIM_ResponsavelLegalCpfCnpj: [null, [Validators.required]],
			SIM_ResponsavelLegalQualificacao: [null, [Validators.required]],
			SIM_TipoTributacao: [null],
			SIM_RequerenteUF: [null],
			SIM_RequerenteMunicipio: [null],
			SIM_RequerenteCEP: [null],
			SIM_RequerenteBairro: [null],
			SIM_RequerenteNumero: [null],
			SIM_RequerenteLogradouro: [null],
			SIM_RequerenteComplemento: [null],
			SIM_AreaUtilizada: [null],
			SIM_InscricaoIptu: [''],
			SIM_Inscricao: [''],
			SIM_CEP: [null],
			SIM_Logradouro: [null],
			SIM_Numero: [null],
			SIM_Bairro: [null],
			SIA_CodigoAtividade: [null, Validators.required],
			SIA_AtividadePrincipal: [{ value: null, disabled: true }, Validators.required],
			partnersData: this.fb.array([this.fb.group({
				SIS_NomeRazaoSocial: [null, Validators.required],
				SIS_CpfCnpj: [null, Validators.required]
			})]),
			formVariants: this.fb.group({
				SOL_Descricao: [null, [Validators.required]],
				term_check: [false, [Validators.requiredTrue]]
			})
		})

		this.form.get('SIM_EspecieRequerente').valueChanges.subscribe(value => {
			this.updateTaxationTypes(value);
		});
		this.handleFindCep();
		this.loadMunicipiosByUF();
		this.loadUfs();
		this.getDataCNAE('SIA_CodigoAtividade', 'SIA_AtividadePrincipal');
		this.getDataInscricaoMunicipal();
		this.formChanges();
	}

	public setEndereco(event: { logradouro: string, numero: string, complemento: string, bairro: string, CEP: string, uf: string, localidade: string }) {
		this.form.patchValue({
			SIM_RequerenteLogradouro: event.logradouro,
			SIM_RequerenteNumero: event.numero,
			SIM_RequerenteComplemento: event.complemento,
			SIM_RequerenteBairro: event.bairro,
			SIM_RequerenteCEP: event.CEP,
			SIM_RequerenteUF: event.uf,
			SIM_RequerenteMunicipio: event.localidade
		});
		this.form.updateValueAndValidity();
	}

	public verifyInscricaoIssAndResetFields(name: string) {
		const simDescricaoValue = this.form.get('SIM_Descricao')?.value;
		this.form.reset();
		this.form.get('SIM_Descricao')?.setValue(simDescricaoValue);
		this.form.updateValueAndValidity();
		const isRequired = name !== 'CADASTRO DE INSCRIÇÃO MUNICIPAL';
		this.updateFieldState('SIM_Inscricao', isRequired);
		this.updateFieldState('SIM_AtividadeMunicipio', isRequired);
		this.activeInscricao = isRequired;
	}

	public resetFormGroup() {
		this.form.reset();
		this.form.updateValueAndValidity();
	}

	public setData() {
		const formData = 'partnersData';
		const data = this.fb.group({
			SIS_NomeRazaoSocial: ['', Validators.required],
			SIS_CpfCnpj: ['', Validators.required]
		});

		(this.form.get(formData) as FormArray).push(data);
	}

	public removeData(index: number) {
		(this.form.get('partnersData') as FormArray).removeAt(index);
	}

	private updateFieldState(fieldName: string, isRequired: boolean) {
		const field = this.form.get(fieldName);
		if (isRequired) {
			field.addValidators([Validators.required]);
			field.updateValueAndValidity();
			field.enable();
		}
		else {
			field.clearValidators();
			field.updateValueAndValidity();
			field.disable();
		}
	}

	private updateTaxationTypes(selectedSpecies: string) {
		if (selectedSpecies === 'Equiparada a empresa') {
			this.filteredTaxationTypes = ['Receita', 'Estimativa'];
		}
		else if (selectedSpecies === 'Autônomo') {
			this.filteredTaxationTypes = ['Primário', 'Médio', 'Superior'];
		}
		else {
			this.filteredTaxationTypes = [];
		}
		this.form.get('SIM_TipoTributacao').setValue(this.filteredTaxationTypes.length ? this.filteredTaxationTypes[0] : '');
	}

	public fieldsDataInscricaoMunicipal(data: any) {
		const formValues = {
			SIM_RequerenteCpfCnpj: data.requerente_cpf_cnpj,
			SIM_RequerenteRazaoSocial: data.requerente_nome_razao_social,
			SIM_RequerenteEmail: data.requerente_email,
			SIM_RequerenteTelefone: data.requerente_telefone,
			SIM_RequerenteCEP: data.requerente_cep,
			// SIM_RequerenteUF: data.requerente_uf,
			// SIM_RequerenteMunicipio: this.formatCityName(data.requerente_municipio),
			SIM_RequerenteLogradouro: data.requerente_logradouro,
			SIM_RequerenteNumero: data.requerente_numero,
			SIM_RequerenteComplemento: data.requerente_complemento,
			SIM_RequerenteBairro: data.requerente_bairro,
			SIM_AtividadeMunicipio: data.requerente_atividade_municipio,
			SIA_CodigoAtividade: data.atividade_codigo,
			SIA_AtividadePrincipal: data.atividade_descricao,
			SIM_CRC: data.contador_crc,
			SIM_ResponsavelRazaoSocial: data.contador_nome,
			SIM_ResponsavelCpfCnpj: data.contador_cpf,
			SIM_ResponsavelLegalNome: data.responsavel_nome,
			SIM_ResponsavelLegalCpfCnpj: data.responsavel_cpf
		};

		return formValues;
	}

	private loadUfs() {
		this.ibgeService.ufs.subscribe({
			next: (response: any) => {
				this.estados = response;
			}
		});
	}

	public loadMunicipiosByUF() {
		this.form.get('SIM_RequerenteUF').valueChanges.subscribe((value: any) => {
			this.ibgeService.municipiosByUf(value).subscribe({
				next: (response: any) => {
					this.municipiosPorEstado = response;
				}
			});
		})
	}

	private handleFindCep() {
		this.form.get('SIM_RequerenteCEP').valueChanges.subscribe({
			next: (value: string) => {
				if (value) {
					if (value.length == 8) {
						this.cepService.getEnderecoByCep(value).subscribe({
							next: (response: any) => {
								if (this.form.get('SIM_RequerenteUF').value == response.uf) {
									this.form.patchValue({
										SIM_RequerenteMunicipio: response.localidade
									});
								}
								else {
									this.form.patchValue({
										SIM_RequerenteUF: response.uf,
										SIM_RequerenteMunicipio: response.localidade
									});
								}
								this.form.updateValueAndValidity();
							}
						});
					}
				}
			}
		});
	}

	fieldsDataInscricao(data) {
		return {
			SIM_AreaUtilizada: data.imovel_area_total,
			SIM_CEP: data.imovel_cep,
			SIM_Logradouro: data.imovel_logradouro,
			SIM_Numero: data.imovel_numero,
			SIM_Bairro: data.imovel_bairro
		}
	}
}