import { Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GlobalService } from "src/app/app-core/lib/global.service";
import { FiscoService } from "src/app/app-core/services/fisco.service";
import swal from 'sweetalert2';

@Directive()
export default class FormRequest {

	@Input() subject: string;
	@Output() formChange = new EventEmitter<any>();

	public form?: FormGroup;

	public awaitRequestCNAE = false;
	public awaitRequestInscricaoMunicipal = false;
	public inscricaoMunicipalField: string;
	public listFieldsInscricaoMunicipal: string[];
	public listFieldsInscricao: string[];
	public tipoImovelField: string;
	public inscricaoField: string;
	public listInscricaoField: string[];
	constructor(
		public fb: FormBuilder,
		public fiscoService: FiscoService,
	) {
		this.form = this.fb.group({});
	}

	//Envia as mudanças dos inputs para o componente pai
	public formChanges() {
		this.form.valueChanges.subscribe({
			next: (response) => {
				for (let el in this.form.controls) {
					if (this.form.controls[el].errors) {
						// console.log(el)
					}
				}
				this.formChange.emit({ form: this.form.getRawValue(), validators: this.form.valid });
			}
		})
	}

	//Procura em uma lista de assuntos se o assunto fornecido no input está presente
	public haveSubject(listSubject: string[]): boolean {
		return listSubject.includes(this.subject);
	}

	public haveFormGroup(formGroupName: string): boolean {
		return this.form?.get(formGroupName) !== null;
	}

	public enabledFields(list: string[]) {
		for (const item of list) {
			const control = this.form.get(item).value;
			if (!control) {
				this.form.get(item).enable();
			}
		}
		this.form.updateValueAndValidity();
	}

	public disabledFields(list: string[]) {
		for (const item of list) {
			this.form.get(item).disable();
		}
		this.form.updateValueAndValidity();
	}

	public eraseDataValueForm(list: string[]) {
		for (const item of list) {
			this.form.get(item).patchValue(null);
		}
		this.form.updateValueAndValidity();
	}

	public getDataCNAE(activityCodeField: string, activityDescriptionField: string) {
		this.form.get(activityCodeField).valueChanges.subscribe((value) => {
			if (value) {
				if (value.length == 9 && !this.awaitRequestCNAE) {
					this.awaitRequestCNAE = true;
					this.fiscoService.getDataCnae(value).subscribe({
						next: (response: any) => {
							if (response.success) {
								this.form.get(activityDescriptionField).patchValue(response.data);
								this.enabledFields([activityDescriptionField]);
							}
							else {
								this.form.get(activityDescriptionField).disable();
								this.alertError("Codigo da atividade invalido!");
								this.form.get(activityDescriptionField).patchValue(null);
							}
							this.awaitRequestCNAE = false;
							this.form.updateValueAndValidity();
						},
						error: () => {
							this.form.get(activityDescriptionField).disable();
							this.alertError("Codigo da atividade invalido!");
							this.awaitRequestCNAE = false;
							this.form.get(activityDescriptionField).patchValue(null);
						}
					})
				}
				else {
					this.form.get(activityDescriptionField).patchValue(null);
					this.form.updateValueAndValidity();
				}
			}
		})
	}

	public fieldsDataInscricaoMunicipal(data) {
		return null;
	}

	public getDataInscricaoMunicipal() {
		this.form.get(this.inscricaoMunicipalField).valueChanges.subscribe((value) => {
			if (value) {
				if (value.length == 7 && !this.awaitRequestInscricaoMunicipal) {
					this.awaitRequestInscricaoMunicipal = true;
					this.fiscoService.getDataInscricaoMunicipal(value).subscribe({
						next: (response: any) => {
							if (response.success) {
								this.form.patchValue(this.fieldsDataInscricaoMunicipal(response.data));
								this.form.updateValueAndValidity();
							}
							else {
								this.alertError("Inscrição municipal inválida!");
							}
							this.awaitRequestInscricaoMunicipal = false;
						},
						error: () => {
							this.alertError("Inscrição municipal inválida!");
							this.awaitRequestInscricaoMunicipal = false;
						}
					});
				}
				else {
					this.eraseDataValueForm(this.listFieldsInscricaoMunicipal)
				}
			}
		})
	}

	public fieldsDataInscricao(data) {
		return null;
	}

	public getDataInscricao(tipoImovelField = null) {
		let tipoImovel;
		if (!tipoImovelField) {
			tipoImovel = this.form.get(this.tipoImovelField)?.value.toLowerCase();
		}
		else {
			tipoImovel = tipoImovelField;
		}
		const inscricao = this.form.get(this.inscricaoField).value;

		this.fiscoService.getDataInscricao(inscricao, tipoImovel).subscribe({
			next: (response: any) => {
				const data = response.data;
				if (response.success) {
					this.form.patchValue(
						this.fieldsDataInscricao(data)
					)
					this.form.updateValueAndValidity();
				}
				else {
					this.alertError("Código de imóvel inválido!");
					this.eraseDataValueForm(this.listFieldsInscricao);
				}
			},
			error: () => {
				this.alertError("Código de imóvel inválido!");
				this.eraseDataValueForm(this.listFieldsInscricao);
			},
		})

	}

	public alertError(msg: string) {
		return swal.fire({
			title: msg,
			icon: 'warning',
		});
	}

	public formatCityName(cityName: string): string {
		const lowercaseWords = ["do", "da", "dos", "das", "de", "e", "a", "o", "em", "para", "com", "por", "sem", "sob", "sobre"];

		return cityName
			.toLowerCase()
			.split(' ')
			.map(word => {
				if (lowercaseWords.includes(word)) {
					return word.toLowerCase();
				}
				return word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(' ');
	}
}