import { iptuFormEnum } from "src/app/Enums/SpecificFormInputEnum";

const getDescription = (subject: string): string | null => {
    switch (subject) {
        case iptuFormEnum.IPTU_ISENÇÃO_VIÚVAS:
            return "Venho requerer a isenção do IPTU, referente ao imóvel acima especificado, para o exercício de atual, em virtude de se enquadrar na opção abaixo assinalada, considerando ainda que possui um único imóvel no município de São Gonçalo do Amarante, no qual reside, além de ser comprovadamente pobre, nos termos do artigo 137, § 1.º, da Lei Complementar n.º 006/2013 de 23/12/2013 (Código Tributário do Município de São Gonçalo do Amarante)."
        case iptuFormEnum.IPTU_ISENÇÃO_SEM_FINS_LUCRATIVOS:
            return `O contribuinte abaixo identificado vem requerer a isenção do IPTU, referente ao imóvel abaixo especificado, em virtude de ser pertencente à sociedade civil sem fins lucrativos destinados ao exercício de atividades culturais, recreativas ou esportivas.`
        case iptuFormEnum.IPTU_ISENÇÃO_CEDIDO_GRATUITAMENTE:
            return `Venho requerer a isenção do IPTU, referente ao imóvel abaixo especificado, para o exercício atual, em virtude de ser imóvel cedido, gratuitamente, em sua totalidade, para uso exclusivo da União, do Estado, do Município ou de suas autarquias e fundações.`
        case iptuFormEnum.IPTU_ISENÇÃO_SERVIDOR_PÚBLICO:
            return `Venho requerer a isenção do IPTU, referente ao imóvel abaixo especificado, para o exercício atual, em virtude de ser servidor público municipal efetivo.`
        case iptuFormEnum.IPTU_ISENÇÃO_LOCADO_NO_MUNICÍPIO:
            return `Venho requerer a isenção do IPTU, referente ao imóvel abaixo especificado, para o exercício atual, em virtude de ser imóvel locado ou cedido ao município de São Gonçalo do Amarante.`
        default:
            return null;
    }
}

export default getDescription;