import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class ProcessCorrectionService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'processo_correcao';
        this.idField = 'PCOR_CodigoCorrecao';
    }
}
