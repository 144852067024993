import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class DepartmentService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'departamento';
        this.idField = 'DEP_CodigoDepartamento';
        this.model = 'DepartamentoModel';
    }
}
