// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Theme Routing
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { PaginacaoComponent } from './paginacao.component';

@NgModule({
    imports: [CommonModule, FormsModule, NgxMaskModule.forRoot(), NgxUpperCaseDirectiveModule],
    declarations: [PaginacaoComponent],
    exports: [PaginacaoComponent],
})
export class PaginacaoModule {}
