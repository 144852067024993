import { Component, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { ProcessMailService } from 'src/app/app-core/services/process-mail.service';
import { IGetEntityProcessMailResponse, IProcessMail } from 'src/app/interfaces/process-mail.interface';

@Component({
    selector: 'app-view-mail',
    templateUrl: './view-mail.component.html',
    styleUrls: ['./view-mail.component.scss'],
    providers: [CryptoService, ProcessMailService, MessageService],
})
export class ViewMailComponent implements OnInit {
    constructor(
        private cryptoService: CryptoService,
        public global: GlobalService,
        private route: ActivatedRoute,
        public processMailService: ProcessMailService,
        public messageService: MessageService,
    ) {}

    message: IProcessMail;
    editorConfig = {
        base_url: '/tinymce',
        suffix: '.min',
        menubar: false,
        statusbar: false,
        plugins:
            'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        toolbar:
            'undo redo | blocks | bold italic underline strikethrough | font formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        wordcount_display: false,
        language_url: '/assets/languages/pt_BR.js',
        language: 'pt_BR',
        content_style: 'body { font-family:Inter, sans-serif; font-size:14px; }',
    };

    responseData = {
        responseBody: null,
        messageId: null,
        senderId: this.global.user().USR_CodigoUsuario,
    };

    ngOnInit(): void {
        const params = this.route.snapshot.paramMap;
        const messageId = this.cryptoService.decrypt(String(params.get('messageId')));

        this.getMailData(Number(messageId));
    }

    async getMailData(messageId: number) {
        await this.processMailService.getEntity(messageId).subscribe({
            next: (response: IGetEntityProcessMailResponse) => {
                this.message = response.entity;
                this.responseData.messageId = this.message.PMSG_CodigoMensagem;

                document.querySelector('title').text = this.message.PMSG_Assunto;
            },
        });
    }

    async onSendResponse() {
        await this.processMailService.sendResponse(this.responseData).subscribe({
            next: () => {
                this.getMailData(this.message.PMSG_CodigoMensagem);

                this.messageService.add({
                    severity: 'success',
                    closable: true,
                    icon: 'ph-star',
                    summary: 'Sucesso',
                    detail: 'Resposta enviada!',
                });

                this.responseData.responseBody = '';
            },
        });
    }

    async onResponseEspecifMessage(messageBody: string) {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

        const contentBloquote = `
        <blockquote>
            <p><em>Em reposta a</em></p>
            ${messageBody}
        </blockquote></br></br>`;

        this.responseData.responseBody = contentBloquote;
    }

    hello() {
        console.log('teste de função');
    }
}
