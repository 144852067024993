import { Component } from '@angular/core';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { AuditService } from 'src/app/app-core/services/audit.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  providers: [AuditService]
})
export class AuditComponent extends GerinusBD {
  public auditList: any[] = [];
  public customTitle: string = 'Consulta de Auditoria';

  constructor(
    public global: GlobalService,
    private auditService: AuditService,
  ) {
    super(global, auditService);
    this.listAll();
  }

}
