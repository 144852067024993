import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable, { Table } from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { LoginService } from 'src/app/app-core/services/login.service';
import { ProcessStatusService } from 'src/app/app-core/services/process-status.service';
import { ProcessService } from 'src/app/app-core/services/process.service';

import { SecondsToDaysPipe } from 'src/app/date-to-days-pipe.pipe';
import { IProcessesFoundResponseSchema, ITransmissionsResponseSchema } from 'src/app/interfaces/proccess.interface';

@Component({
	selector: 'app-search-process',
	templateUrl: './search-process.component.html',
	styleUrls: ['./search-process.component.scss'],
	providers: [ProcessService, LoginService, MessageService, ProcessStatusService, SecondsToDaysPipe]
})
export class SearchProcessComponent implements OnInit {

	constructor(
		public global: GlobalService,
		private route: ActivatedRoute,
		private messageService: MessageService,
		private processService: ProcessService,
		public loginService: LoginService,
		private secondsToDaysPipe: SecondsToDaysPipe
	) { }

	processList: IProcessesFoundResponseSchema[] = [];
	searchValue: string;
	first: number = 0;
	rows: number = 10;
	loading: boolean = false;
	visible: boolean = false;
	selectedProcess: IProcessesFoundResponseSchema;
	transmissions: ITransmissionsResponseSchema[];
	public isCollapsed = true;

	@ViewChild('dt') table: Table;

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params['searchValue']) {
				this.searchValue = params['searchValue'];
				this.search();
			}
		});
	}

	showDialog(process: IProcessesFoundResponseSchema) {
		this.selectedProcess = process;
		this.visible = true;

		this.getTransmissions(process.PRC_CodigoProcesso);
	}

	public search() {
		const searchData = {
			searchValue: this.searchValue
		}

		this.loading = true;
		this.processService.search(searchData).subscribe({
			next: (response: any) => {
				const data = response.data as IProcessesFoundResponseSchema[];
				console.log(data);
				this.loading = false;
				if (data.length > 0) {
					this.processList = response.data;

					this.messageService.add({
						severity: 'success',
						icon: 'ph ph-check-circle',
						closable: true,
						summary: `${data.length} processos encontrados.`,
					});
				}
				else {
					this.processList = [];
					this.messageService.add({
						severity: 'warn',
						icon: 'ph ph-warning',
						closable: true,
						summary: `Nenhum processo foi encontrado para a sua busca!`,
					});
				}
			},
			error: () => {
				this.loading = false;
				console.log('Erro na busca');
			}
		});
	}

	getTransmissions(processId: number) {
		this.processService.getTransmissions(processId).subscribe({
			next: (response: ITransmissionsResponseSchema[]) => {
				this.transmissions = response
			}
		})
	}

	generatePdf() {
		const doc = new jsPDF('p', 'mm', 'a4');
		const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
		const processId = this.selectedProcess.PRC_NumeroProcesso;
		const requester = this.selectedProcess.SOLR_NomeRazaoSocial ? this.selectedProcess.SOLR_NomeRazaoSocial : this.selectedProcess.PE_Nome
		const subject = this.selectedProcess.AS_Descricao;
		const organization = this.selectedProcess.OR_Sigla + ' - ' + this.selectedProcess.OR_Descricao;
		const receivedDate = new Date(this.selectedProcess.recebimento).toLocaleString();
		const currentDepartment = this.selectedProcess.nomeDepartamentoAtual;
		const totalTime = this.secondsToDaysPipe.transform(this.selectedProcess.total_time);
		const currentDate = new Date();

		const logoProtocolize = new Image();
		logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
		doc.addImage(logoProtocolize, 'png', 60, 5, 95, 18);

		doc.setFont('Helvetica', 'bold');
		doc.setFontSize(16);
		doc.setTextColor(40, 40, 40);
		doc.text(`Processo #${processId}`, 14, 30);

		doc.setDrawColor(200, 200, 200);
		doc.line(14, 35, pageWidth - 14, 35);

		autoTable(doc, {
			body: [
				[
					{
						content: 'Solicitante:',
						styles: {
							halign: 'left',
							valign: 'middle',
							font: 'Inter-SemiBold',
							cellWidth: 'wrap',
							fontSize: 9,
							textColor: '#0a2156',
							cellPadding: { left: 4, top: 2, right: 4 },
						},
					},
				],
				[
					{
						content: requester,
						styles: {
							halign: 'left',
							fontSize: 10,
							textColor: '#687489',
							cellPadding: {
								left: 4,
								top: 2,
								right: 4,
								bottom: 2,
							},
						},
					},
				],
			],
			theme: 'plain',
			startY: 40,
			styles: {
				fillColor: '#f2f5f9',
			},
		});

		autoTable(doc, {
			body: [
				[
					{
						content: 'Assunto:',
						styles: {
							halign: 'left',
							valign: 'middle',
							font: 'Inter-SemiBold',
							cellWidth: 'wrap',
							fontSize: 9,
							textColor: '#0a2156',
							cellPadding: { left: 4, top: 2, right: 4 },
						},
					},
				],
				[
					{
						content: subject,
						styles: {
							halign: 'left',
							fontSize: 10,
							textColor: '#687489',
							cellPadding: {
								left: 4,
								top: 2,
								right: 4,
								bottom: 2,
							},
						},
					},
				],
			],
			theme: 'plain',
			startY: 55,
			styles: {
				fillColor: '#f2f5f9',
			},
		});

		autoTable(doc, {
			body: [
				[
					{
						content: 'Órgão/Departamento',
						styles: {
							halign: 'left',
							valign: 'middle',
							font: 'Inter-SemiBold',
							cellWidth: 'wrap',
							fontSize: 9,
							textColor: '#0a2156',
							cellPadding: { left: 4, top: 2, right: 4 },
						},
					},
				],
				[
					{
						content: organization + '/' + currentDepartment,
						styles: {
							halign: 'left',
							fontSize: 10,
							textColor: '#687489',
							cellPadding: {
								left: 4,
								top: 2,
								right: 4,
								bottom: 2,
							},
						},
					},
				],
			],
			theme: 'plain',
			startY: 70,
			styles: {
				fillColor: '#f2f5f9',
			},
		});

		autoTable(doc, {
			body: [
				[
					{
						content: 'Data de Recebimento',
						styles: {
							halign: 'left',
							valign: 'middle',
							font: 'Inter-SemiBold',
							cellWidth: 'auto',
							fontSize: 9,
							textColor: '#0a2156',
							cellPadding: { left: 4, top: 2, right: 4 },
						},
					},
					{
						content: 'Tempo total do processo',
						styles: {
							halign: 'left',
							valign: 'middle',
							font: 'Inter-SemiBold',
							cellWidth: 'auto',
							fontSize: 9,
							textColor: '#0a2156',
							cellPadding: { left: 4, top: 2, right: 4 },
							lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
							lineColor: '#ffffff',
						},
					},
				],
				[
					{
						content: receivedDate,
						styles: {
							halign: 'left',
							fontSize: 10,
							textColor: '#687489',
							cellPadding: {
								left: 4,
								top: 2,
								right: 4,
								bottom: 2,
							},
						},
					},
					{
						content: totalTime,
						styles: {
							halign: 'left',
							fontSize: 10,
							textColor: '#687489',
							cellPadding: {
								left: 4,
								top: 2,
								right: 4,
								bottom: 2,
							},
							lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
							lineColor: '#ffffff',
						},
					},
				],
			],
			theme: 'plain',
			startY: 85,
			styles: {
				fillColor: '#f2f5f9',
			},
		});

		doc.setFont('Helvetica', 'bold');
		doc.setFontSize(14);
		doc.setTextColor(40, 40, 40);
		doc.text('Tramitações', 14, 109);

		const tramites = this.transmissions.map((transmission, index) => {
			const startDate = new Date(transmission.PRT_DataInicio).toLocaleString();
			const status = this.processService.getStatusDescription(transmission.Status);
			const department = transmission.SiglaOrgaoDepartamentoDestino + ' - ' + transmission.nomeDepartamentoAtual;
			const duration = this.secondsToDaysPipe.transform(transmission.TempoDecorrido);

			return [
				index + 1,
				startDate,
				status,
				department,
				duration,
			];
		});

		autoTable(doc, {
			head: [['#', 'Início', 'Status', 'Órgão/Unidade', 'Tempo Decorrido']],
			body: tramites,
			startY: 115,
			theme: 'striped',
			headStyles: {
				fillColor: '#417bff',
				textColor: [255, 255, 255],
			},
			styles: {
				fontSize: 10,
				cellPadding: 2,
				overflow: 'linebreak',
				valign: 'middle'
			},
			columnStyles: {
				0: { cellWidth: 10 },
				1: { cellWidth: 40 },
				2: { cellWidth: 40 },
				3: { cellWidth: 50 },
				4: { cellWidth: 40 },
			},
		});

		const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

		doc.setDrawColor(200, 200, 200);
		doc.line(14, pageHeight - 25, pageWidth - 14, pageHeight - 25);

		doc.setFont('Helvetica', 'normal');
		doc.setFontSize(10);
		doc.setTextColor(100, 100, 100);
		doc.text(
			`Documento emitido pelo sistema de protocolos do município de São Gonçalo do Amarante - CE em ${currentDate.toLocaleDateString('pt-BR')} às ${currentDate.toLocaleTimeString('pt-BR')}`,
			pageWidth / 2,
			pageHeight - 15, { align: 'center', maxWidth: 100 }

		);

		doc.save(`Processo N° ${this.selectedProcess.PRC_NumeroProcesso}.pdf`);
	}

	toggleResponsiveMenu() {
		this.isCollapsed = !this.isCollapsed;
	}
}
