import { Component, Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'view-document-form',
  templateUrl: './view-document-form.component.html',
  styleUrls: ['./view-document-form.component.scss'],
  providers: []
})
export class ViewDocumentFormComponent implements OnInit {

  @Input() iSrc: SafeResourceUrl;
  public iframeCarregado = false;
  public safeUrl: SafeResourceUrl

  constructor(private sanitizer: DomSanitizer) {
    // console.log("view-document.form.constructor safeUrl", this.safeUrl)
  }

  ngOnInit(): void {
    // console.log("[view.document-form.ngOnIniti] iSrc", this.iSrc)
  }
  
  public onIframeCarregado() {
    this.iframeCarregado = true;
  }

}
