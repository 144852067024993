import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';

@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() size: 'sm' | 'lg';
    @Input() url?: string;

    constructor(public global: GlobalService) {}

    ngOnInit(): void {}
}
