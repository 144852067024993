import { Component, EventEmitter, Output } from '@angular/core';
import { IbgeService } from 'src/app/app-core/lib/ibge.service';

@Component({
  selector: 'app-select-uf-estado',
  templateUrl: './select-uf-estado.component.html',
  styleUrls: ['./select-uf-estado.component.scss'],
  providers: [IbgeService]
})
export class SelectUfEstadoComponent {

  @Output() onChange = new EventEmitter<{ uf: string, estado: string }>();

  public estados: any[] = [];
  public municipiosPorEstado: any[] = [];

  public form = {
    uf: "",
    estado: ""
  }

  constructor(
    private ibgeService: IbgeService,
  ) {
    this.loadUfs();
  }

  private loadUfs() {
    this.ibgeService.ufs.subscribe({
      next: (response: any) => {
        this.estados = response;
      }
    });
  }

  public loadMunicipiosByUF() {
    this.ibgeService.municipiosByUf(this.form.uf).subscribe({
      next: (response: any) => {
        this.municipiosPorEstado = response;
        this.emitEvent();
      }
    });
  }

  public emitEvent() {
    this.onChange.emit(this.form);
  }
}
