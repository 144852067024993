import { FormGroup } from "@angular/forms";
import { GlobalService } from "src/app/app-core/lib/global.service";
import { FiscoService } from "src/app/app-core/services/fisco.service";
import { itbiFormEnum } from "src/app/Enums/SpecificFormInputEnum";

export default class FormItbi {
    public form?: FormGroup;
    public inscricaoField: string;
    public tipoImovelField: string;
    public enableDisableListField: string[];
    private enableRequesition = false;
    constructor(
        public fiscoService: FiscoService,
        public global: GlobalService
    ) { }

    getEnumItbi(type: string): string {
        return itbiFormEnum[type];
    }

    public enabledFields(list: string[]) {
        for (const item of list) {
            const control = this.form.get(item).value;
            if (!control) {
                this.form.get(item).enable();
            }
        }
        this.form.updateValueAndValidity();
    }

    public disabledFields(list: string[]) {
        for (const item of list) {
            this.form.get(item).disable();
        }
        this.form.updateValueAndValidity();
    }

    public patchValueformControl(data) {
        return null;
    }

    public resettingValuesChangingPropertyType() {
        this.form.get(this.tipoImovelField).valueChanges.subscribe((responseValue) => {
            this.form.get(this.inscricaoField).setValue('');
            this.eraseDataValueForm();
            this.disabledFields(this.enableDisableListField);
            this.form.updateValueAndValidity();
        })
    }

    public getDataInscricao() {
        const tipoImovel = this.form.get(this.tipoImovelField)?.value.toLowerCase();
        const inscricao = this.form.get(this.inscricaoField).value;
        this.disabledFields(this.enableDisableListField);
        this.fiscoService.getDataInscricao(inscricao, tipoImovel).subscribe({
            next: (response: any) => {
                const data = response.data;
                if (response.success) {
                    this.form.patchValue(
                        this.patchValueformControl(data)
                    )
                    this.form.updateValueAndValidity();
                    this.enabledFields(this.enableDisableListField);
                }
                else {
                    this.warnInscricao();
                    this.disabledFields(this.enableDisableListField);
                }
            },
            error: () => {
                this.warnInscricao();
                this.disabledFields(this.enableDisableListField);
            },
        })

    }

    private eraseDataValueForm() {
        for (const item of this.enableDisableListField) {
            this.form.get(item).patchValue(null);
        }
        this.form.updateValueAndValidity();
    }


    public warnInscricao() {
        return this.global.swal.fire({
            title: 'Inscrição do imóvel inválida!',
            icon: 'warning',
        });
    }

    public capitalizeFirstLetter(string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}