import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../lib/global.service';


@Injectable({
  providedIn: 'root'
})
export class AuditService extends GerinusService {

  constructor(
    public http: HttpClient,
    public global: GlobalService,
  ) {
    super(http);
    this.entityName = 'auditoria';
    this.idField = 'AUD_CodigoAuditoria';
    this.model = 'AuditoriaModel';
    this.orderBy = 'AUD_DataHora';
  }
}
