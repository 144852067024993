import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DashboardService } from 'src/app/app-core/services/dashboard.service';

@Component({
    selector: 'dashboard-diretor-departamento',
    templateUrl: './dashboard-diretor-departamento.component.html',
    styleUrls: ['../../pages/dashboard/dashboard.component.scss'],
    providers: [DashboardService],
})
export class DashboardDiretorDepartamentoComponent implements OnInit {
    public processAmountFromOrganization: number;
    public processAmount: number;
    public processInSector: number;
    public transferredProcess: number;
    public archivedProcess: number;
    public resolvedProcess: number;
    public processWaitingEvaluation: number;
    public processInEvaluation: number;
    public processWaitingCorrection: number;

    public isChartLoading: boolean = true;

    constructor(
        public global: GlobalService,
        public dashboardService: DashboardService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.processAmountFromOrganization = await this.dashboardService.getTotal(
            'processService',
            `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao}`,
        );

        this.processInSector = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`,
        );

        this.processAmount = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`,
        );

        this.processWaitingEvaluation = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "Status" = 1 and "PRT_DataFim" IS NULL`,
        );

        this.processInEvaluation = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "Status" = 2 and "PRT_DataFim" IS NULL`,
        );

        this.processWaitingCorrection = await this.dashboardService.getTotal(
            'processService',
            `"PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "Status" = 5 and "PRT_DataFim" IS NULL`,
        );

        this.transferredProcess = await this.dashboardService.getTotal(
            'processService',
            `"PRT_CodigoDepartamentoTramitador" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`,
        );

        this.resolvedProcess = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 4 and "PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`,
        );

        this.archivedProcess = await this.dashboardService.getTotal(
            'processService',
            `"Status" = 3 and "PRT_DepartamentoAtual" = ${this.global.user().PE_CodigoDepartamento} and "PRT_DataFim" IS NULL`,
        );

        setTimeout(() => {
            this.isChartLoading = false;
        }, 1000);
    }
}
