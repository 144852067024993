import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'empty-data',
    templateUrl: './empty-data.component.html',
})
export class EmptyDataComponent implements OnInit {
    @Input() title = 'Nenhuma informação encontrada';
    @Input() text = 'Nenhuma informação foi adicionada até o momento';
    @Input() icon = 'fas fa-box-open';
    constructor() {}

    ngOnInit(): void {}
}
