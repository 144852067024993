import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class GroupService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'grupo';
        this.companyField = 'GRP_CodigoEmpresa';
        this.idField = 'GRP_CodigoGrupo';
    }
}
