import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CepService {

    private url(cep: string) {
        return `https://viacep.com.br/ws/${cep}/json/`;
    }

    constructor(private httpClient: HttpClient) {}

    /**
     * Obtém dados de um endereço com base no CEP fornecido.
     * @param {string} cep - O CEP do endereço a ser pesquisado sem máscara.
     */
    public getEnderecoByCep(cep: string) {
        return this.httpClient.get(this.url(cep));
    }

}