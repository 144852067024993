import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MailComposeModalComponent } from '../mail-compose-modal/mail-compose-modal.component';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { IGetEntityProccessResponse } from 'src/app/interfaces/getEntity/getEtityProccessResponse';
import { IProccess } from 'src/app/interfaces/proccess.interface';

@Component({
    selector: 'header-mail',
    templateUrl: './header-mail.component.html',
    styleUrls: ['./header-mail.component.scss'],
    providers: [ProcessService],
})
export class HeaderMailComponent implements OnInit {
    @ViewChild(MailComposeModalComponent) mailComposeModal!: MailComposeModalComponent;

    constructor(
        public processService: ProcessService,
        private route: ActivatedRoute,
        private cryptoService: CryptoService,
    ) {}

    tabMenuItems: MenuItem[] | undefined;
    activeMenuItem: MenuItem | undefined;
    processId: string;
    process: IProccess;

    @Output() openComposeModal: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        const params = this.route.snapshot.paramMap;
        this.processId = this.cryptoService.decrypt(String(params.get('id')));

        this.getProcess();

        this.tabMenuItems = [
            {
                label: 'Recebidos',
                icon: 'ph-bold text-lg ph-tray',
                routerLink: `/process/view/${params.get('id')}/mail/received`,
            },
            {
                label: 'Enviados',
                icon: 'ph-bold text-lg ph-paper-plane-right',
                routerLink: `/process/view/${params.get('id')}/mail/sent`,
            },
            {
                label: 'Favoritos',
                icon: 'ph-bold text-lg ph-star',
                routerLink: `/process/view/${params.get('id')}/mail/favorited`,
            },
            {
                label: 'Arquivados',
                icon: 'ph-bold text-lg ph-archive',
                routerLink: `/process/view/${params.get('id')}/mail/archived`,
            },
        ];

        this.activeMenuItem = this.tabMenuItems[0];
    }

    async getProcess() {
        await this.processService.getEntity(this.processId).subscribe({
            next: (response: IGetEntityProccessResponse) => {
                this.process = response.entity;
            },
        });
    }

    onActiveItemChange(event: MenuItem) {
        this.activeMenuItem = event;
    }

    openMailComposeModal() {
        this.mailComposeModal.showDialog();
    }

    getProcessStatusClass(status: number): string {
        switch (status) {
            case 1:
                return 'badge-primary';
            case 2:
                return 'badge-success';
            default:
                return 'badge-default';
        }
    }
}
