import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, timer } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GlobalService } from '../lib/global.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private readonly inactivityTime = 60 * 60 * 1000; // 60 minutos

  constructor(
    private router: Router,
    private global: GlobalService
  ) {
    this.monitorUserActivity();
  }

  private monitorUserActivity() {
    merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'click')
    )
    .pipe(
      debounceTime(500),
      switchMap(() => {
        return timer(this.inactivityTime);
      })
    )
    .subscribe(() => this.handleInactivity());
  }

  private handleInactivity() {
    if (this.global.user()) {
      this.router.navigate(['/principal'], { queryParams: { logout: 'true' } });
    }
  }
}
