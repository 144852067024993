import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import FormRequest from '../common/formRequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { itbiFormEnum } from 'src/app/Enums/SpecificFormInputEnum';
import { ApplicationItbiComponent } from './application-itbi/application-itbi.component';
import { CepService } from 'src/app/app-core/lib/cep.service';
import { EnderecoFormComponent } from 'src/app/components/endereco-form/endereco-form.component';
import { GenericValidator } from '../utilities/genericValidator';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { RequestItbiComponent } from './request-itbi/request-itbi.component';

@Component({
	selector: 'app-form-request-itbi',
	templateUrl: './form-request-itbi.component.html',
	styleUrls: ['./form-request-itbi.component.scss'],
	providers: [CepService]
})
export class FormRequestItbiComponent extends FormRequest implements OnInit, AfterViewInit, OnChanges {

	@ViewChild(ApplicationItbiComponent) applicationItbiComponent: ApplicationItbiComponent;
	@ViewChild('requestItbi') requestItbiComponent: RequestItbiComponent;
	@ViewChild(EnderecoFormComponent) enderecoFormComponent: EnderecoFormComponent;

	@Input() applicantDetails;

	public applicationComponentExist = false;
	public checkApplicantData = false;
	public subjectChangedOnce = false;
	public listRequest = [
		itbiFormEnum.ITBI_SOLICITACAO_NÃO_INCIDENCIA,
		itbiFormEnum.ITBI_SOLICITAÇÃO_DE_ISENÇÃO_DE_ITBI
	]

	public requiredFieldsApplication = [
		'SOLIT_AdquirenteNome',
		'SOLIT_AdquirenteCPF',
		'SOLIT_AdquirenteUF',
		'SOLIT_AdquirenteMunicipio',
		'SOLIT_AdquirenteLogradouro',
		'SOLIT_AdquirenteNumero',
		'SOLIT_AdquirenteBairro',
		'SOLIT_AdquirenteCEP',
		'SOLIT_TransmitenteNome',
		'SOLIT_TransmitenteUF',
		'SOLIT_TransmitenteMunicipio',
		'SOLIT_TransmitenteLogradouro',
		'SOLIT_TransmitenteNumero',
		'SOLIT_TransmitenteBairro',
		'SOLIT_TransmitenteCEP',
		'SOLIT_NaturezaTransacao',
		'SOLIT_TransacaoValorFinanciado',
		'SOLIT_TransacaoValorNaoFinanciado',
		'SOLIT_TransacaoInscricao',
		'SOLIT_TransacaoEndereco',
		'SOLIT_TransacaoAreaTerreno'
	];

	public requiredFieldsRequests = [
		'SOLIT_SolicitanteNomeRazaoSocial',
		'SOLIT_SolicitanteCpfCnpj',
		'SOLIT_SolicitanteTelefone',
		'SOLIT_SolicitanteUF',
		'SOLIT_SolicitanteMunicipio',
		'SOLIT_SolicitanteLogradouro',
		'SOLIT_SolicitanteNumero',
		'SOLIT_SolicitanteBairro',
		'SOLIT_SolicitanteCEP',
		'SOLIT_Logradouro',
		'SOLIT_Numero',
		'SOLIT_Bairro',
		'SOLIT_CEP',
		'SOLIT_Inscricao',
		'SOLIT_NaturezaTransacao'
	];

	constructor(
		public fb: FormBuilder,
		public fiscoService: FiscoService,
	) {
		super(fb, fiscoService);
	}

	ngOnInit(): void {
		this.initForm();
		this.formChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {

		if (changes.subject && this.subjectChangedOnce) {
			this.resetFormGroupsBySubject();
		}
		else {
			this.subjectChangedOnce = true;
		}
	}

	ngAfterViewInit(): void {
		this.initApplicationItbi();
		this.initRequestItbi();
	}

	initForm() {
		this.renderForm();
		if (this.haveSubject(this.listRequest)) {
			this.setValidatorsForm(this.requiredFieldsRequests);
		}
		if (this.haveSubject([itbiFormEnum.ITBI_REQUERIMENTO])) {
			this.applicationComponentExist = true;
			this.prepareValidationApplication();
		}
	}

	renderForm() {
		this.form = this.fb.group({
			SOLIT_SolicitanteNomeRazaoSocial: [''],
			SOLIT_SolicitanteCpfCnpj: [''],
			SOLIT_SolicitanteEstadoCivil: [''],
			SOLIT_SolicitanteRG: [''],
			SOLIT_SolicitanteTelefone: [''],
			SOLIT_SolicitanteEmail: [''],
			SOLIT_SolicitanteUF: [''],
			SOLIT_SolicitanteMunicipio: [''],
			SOLIT_SolicitanteLogradouro: [''],
			SOLIT_SolicitanteNumero: [''],
			SOLIT_SolicitanteBairro: [''],
			SOLIT_SolicitanteComplemento: [''],
			SOLIT_SolicitanteCEP: [''],
			SOLIT_Inscricao: [''],
			SOLIT_TipoImovel: ['Urbano'],
			SOLIT_ValorDeclarado: [null],
			SOLIT_AreaTerreno: [null],
			SOLIT_AreaEdificadaTotal: [null],
			SOLIT_UF: ['CE'],
			SOLIT_Municipio: ['São Gonçalo do Amarante'],
			SOLIT_Logradouro: [''],
			SOLIT_Numero: [''],
			SOLIT_Bairro: [''],
			SOLIT_Complemento: [''],
			SOLIT_CEP: [''],
			SOLIT_NaturezaOperacao: [''],
			SOLIT_SolicitanteConjugue: [''],
			SOLIT_AdquirenteNome: [''],
			SOLIT_AdquirenteCPF: [''],
			SOLIT_AdquirenteUF: [''],
			SOLIT_AdquirenteMunicipio: [''],
			SOLIT_AdquirenteLogradouro: [''],
			SOLIT_AdquirenteNumero: [''],
			SOLIT_AdquirenteBairro: [''],
			SOLIT_AdquirenteComplemento: [''],
			SOLIT_AdquirenteCEP: [''],
			SOLIT_TransmitenteNome: [''],
			SOLIT_TransmitenteCPF: [''],
			SOLIT_TransmitenteUF: [''],
			SOLIT_TransmitenteMunicipio: [''],
			SOLIT_TransmitenteLogradouro: [''],
			SOLIT_TransmitenteNumero: [''],
			SOLIT_TransmitenteBairro: [''],
			SOLIT_TransmitenteComplemento: [''],
			SOLIT_TransmitenteCEP: [''],
			SOLIT_NaturezaTransacao: [''],
			SOLIT_TransacaoInscricao: [''],
			SOLIT_TransacaoEndereco: [''],
			SOLIT_TransacaoNumero: [''],
			SOLIT_TransacaoComplemento: [''],
			SOLIT_TransacaoLote: [''],
			SOLIT_TransacaoQuadra: [''],
			SOLIT_TransacaoTipoImovel: [''],
			SOLIT_TransacaoFracaoIdeal: [''],
			SOLIT_TransacaoImobiliaria: [''],
			SOLIT_TransacaoAreaTerreno: [null],
			SOLIT_TransacaoAreaPrivada: [null],
			SOLIT_TransacaoAreaTotal: [null],
			SOLIT_TransacaoValorFinanciado: [null],
			SOLIT_TransacaoValorNaoFinanciado: [null],
			SOLIT_TransacaoTotalDeclarado: [null],
			SOLIT_Descricao: [''],
			formVariants: this.fb.group({
				SOL_Descricao: ['', Validators.required],
				termCheck: [false]
			})
		}, { validators: this.acquirerValidator });

	}

	private initApplicationItbi() {
		if (this.applicationComponentExist && this.applicationItbiComponent.form.pristine) {
			this.applicationItbiComponent.form.valueChanges.subscribe((response) => {
				this.form.patchValue(
					this.applicationItbiComponent.form.getRawValue()
				);
				this.form.updateValueAndValidity();
			});
		}
	}
	private initRequestItbi() {
		if (this.requestItbiComponent) {
			this.requestItbiComponent.form.valueChanges.subscribe((response) => {
				this.form.patchValue(
					this.requestItbiComponent.form.getRawValue()
				);
				this.form.updateValueAndValidity();
			});
		}
	}

	getAddressImmobile(event) {
		this.form.get('SOLIT_Municipio').setValue(event.localidade);
		this.form.get('SOLIT_Bairro').setValue(event.bairro);
		this.form.get('SOLIT_Logradouro').setValue(event.logradouro);
		this.form.get('SOLIT_UF').setValue(event.uf);
		this.form.get('SOLIT_Numero').setValue(event.numero);
		this.form.get('SOLIT_Complemento').setValue(event.complemento);
		this.form.get('SOLIT_CEP').setValue(event.CEP);
	}

	getApplicationItbi() {
		this.form.setValue(this.applicationItbiComponent.form.getRawValue());
		this.form.updateValueAndValidity();
	}

	private prepareValidationApplication() {
		this.form.get('formVariants').get('termCheck').setValidators(Validators.requiredTrue);
		this.form.get('SOLIT_TransmitenteCPF').setValidators(
			[
				Validators.required,
				GenericValidator.isValidCpfOrCnpj(),
				// GenericValidator.compareValue(this.form?.get('SOLIT_AdquirenteCPF')?.value)
			]
		);
		this.setValidatorsForm(this.requiredFieldsApplication);
		this.form.updateValueAndValidity();
	}

	private setValidatorsForm(list: string[]) {
		for (const field of list) {
			this.form.get(field).setValidators(Validators.required);
		}
	}

	private resetFormGroupsBySubject() {
		this.applicationComponentExist = false;
		if (this.enderecoFormComponent) {
			this.enderecoFormComponent.reset();
		}
		this.form.patchValue(this.fb.group({}));
		this.initForm();
		this.form.updateValueAndValidity();
		this.formChanges();
	}

	acquirerValidator(form: FormGroup) {
		const transmitenteCPF = form.get('SOLIT_TransmitenteCPF').value;
		const adquirenteCPF = form.get('SOLIT_AdquirenteCPF').value;
		if (transmitenteCPF && adquirenteCPF && transmitenteCPF === adquirenteCPF) {
			return { acquirerMismatch: true };
		}
		return null;
	}
}
