import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { GlobalService } from './app-core/lib/global.service';
@Directive({
    selector: '[DropZone]',
})
export class DropzoneDirective {
    constructor(public global: GlobalService) {}

    @Output() onFileDropped = new EventEmitter<any>();

    @HostBinding('style.opacity') private opacity = '1';
    @HostBinding('style.border') private border = '2px dotted #97A3B6';
    @HostBinding('style.backgroundColor') private background = '#fff';

    @HostListener('dragover', ['$event'])
    public onDragOver(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.border = 'dotted 2px #417BFF';
        this.background = '#eef5ff';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        this.border = 'dotted 2px #97A3B6';
        this.background = '#fff';
    }

    @HostListener('drop', ['$event']) public ondrop(evt): any {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        this.border = 'dotted 2px #97A3B6';
        this.background = '#fff';
        const files = evt.dataTransfer.files;
        const maxFileSize = 40 * 1024 * 1024; //40 MB em Bytes
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
    }
}
